import styled from "styled-components";
import { motion } from "framer-motion";

/* ================================== */
/*  STYLED COMPONENTS                 */
/* ================================== */

export const NavbarWrapper = styled.div`
  /* border: 5px solid green; */

  position: fixed;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
  top: 0;
  z-index: 9 !important;
  transition-timing-function: ease-in;
  transition: 0.3s ease-in;
  text-shadow: 3px 3px 5px rgba(38, 50, 56, 1); //#263238
  background-color: ${(props) =>
    props.color === "#263238" ? "#263238" : "none"};

  @media only screen and (max-width: 960px) {
    align-items: center;
    justify-content: space-around;
  }
`;

//Styled Components - NavbarContainer
export const NavbarContainer = styled.div`
  /* border: 2px solid red; */

  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1100px;
`;

//Styled Components - DesktopLinks
export const DesktopLinks = styled.div`
  /* border: 4px solid pink; */

  z-index: 9 !important;
  &:nth-child(2) {
    order: 2;
  }
  @media only screen and (max-width: 960px) {
    display: none;
  }
`;

//Styled Components - OpenLinkButton for Hamburger
export const OpenLinksButton = styled(motion.button)`
  display: none;

  @media (max-width: 960px) {
    /* border: 1px red solid; */
    border-radius: 50%;
    border: none;
    outline: none;
    background: transparent;
    user-select: none; //Prevent Text Selection
    touch-action: manipulation; // prevent delay of box
    -webkit-tap-highlight-color: "transparent"; //required for iOS touch effect

    display: flex;
    z-index: 99999999999 !important;
    cursor: pointer;
  }
`;

//Styled Components - MobileLinks
export const MobileLinks = styled.nav`
  display: none;

  @media (max-width: 960px) {
    /* border: solid red 10px; */
    z-index: 9 !important;
    position: absolute;
    top: 0;
    left: 0px;
    position: fixed !important;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #607d8b;
  }
`;

//Styled Components - Company Logo
export const Logo = styled(motion.img)`
  /* border: 1px solid red; */
  height: 65px;
  width: 180px;
  z-index: 9 !important;
  cursor: pointer;
  &:nth-child(1) {
    order: 1;
  }
  @media only screen and (max-width: 960px) {
    /* border: 1px solid green; */
    height: 50px;
    width: 150px;
  }
  &:nth-child(1) {
    order: 1;
  }
`;

//Styled Components - Language Icon
export const Language = styled(motion.img)`
  /* border: 1px solid blue; */
  display: none;

  @media only screen and (max-width: 960px) {
    display: block;
    border-radius: 50%;
    height: 28px;
    z-index: 9 !important;
    &:nth-child(1) {
      order: 2;
    }
  }
`;
