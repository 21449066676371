//Existing Array
//Images in Public Folder
export const ClientsData = [
  {
    ///////////////////
    //     AIG      //
    //////////////////
    id: "1",
    photo: "./assets/images/clientsWebpage/clientsWebpage-01.png",
    alt: "AIG",
    text: "AIG",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "../assets/images/clientsWebpage/clientsWebpage-01.png",
    modalTitle: "",
    modalDescription:
      "AIG insurance is known for its global reach, diverse product offerings, financial strength, innovation, and commitment to customer service.  Dynamic has been providing services to AIG since 2007, specialising in sports management and production sourcing with a focus on delivering high-quality and innovative solutions throughout the years.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig1.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig2.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig3.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig4.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig5.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig6.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig7.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig8.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig9.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig10.jpg",
    ],
  },
  {
    ///////////////////
    //       AP     //
    //////////////////
    id: "2",
    photo: "./assets/images/clientsWebpage/clientsWebpage-02.png",
    alt: "AP",
    text: "AUDEMARS PIGUET",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "../assets/images/clientsWebpage/clientsWebpage-02.png",
    modalTitle: "",
    modalDescription:
      "The Audemars Piguet Golf Trophy is a prestigious golf tournament that is held annually at various golf courses around the world and is known for attracting top golfers and celebrities. Dynamic organized the highly acclaimed event in Hong Kong in 2017, showcasing our expertise in event management and commitment to excellence.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/AP/dynamic-ap1.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap2.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap3.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap4.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap5.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap6.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap7.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap8.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap9.jpg",
    ],
  },
  {
    ///////////////////
    //     BNP       //
    //////////////////
    id: "3",
    photo: "./assets/images/clientsWebpage/clientsWebpage-03.png",
    alt: "BNP",
    text: "BNP",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-03.png",
    modalTitle: "",
    modalDescription:
      "BNP Paribas Wealth Management is a premier global wealth management firm that offers a comprehensive range of investment solutions to high-net-worth individuals and families. Since 2011, Dynamic has had the privilege of working with BNP Paribas with engaging golfing events throughout the Asian region. ",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/BNP/dynamic-bnp1.jpg",
      "../assets/images/ModalDynamicImages/BNP/dynamic-bnp2.jpg",
      "../assets/images/ModalDynamicImages/BNP/dynamic-bnp3.jpg",
      "../assets/images/ModalDynamicImages/BNP/dynamic-bnp4.jpg",
    ],
  },
  {
    ///////////////////
    //   CENTURION   //
    //////////////////
    id: "4",
    photo: "./assets/images/clientsWebpage/clientsWebpage-04.png",
    alt: "Centurion",
    text: "Centurion",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-04.png",
    modalTitle: "",
    modalDescription:
      "The Centurion Card, also known as the Amex Black Card, is an exclusive and prestigious invitation-only charge card that offers a range of elite benefits. Dynamic has had the privilege of being the chosen agency to help organise Centurion’s annual golf day for their clients since 2017, and we are honoured to continue providing our professional services to such a distinguished and esteemed brand.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion1.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion3.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion4.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion6.jpg",
    ],
  },
  {
    ///////////////////
    //     CITI      //
    //////////////////
    id: "5",
    photo: "./assets/images/clientsWebpage/clientsWebpage-05.png",
    alt: "Citi",
    text: "",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-05.png",
    modalTitle: "",
    modalDescription:
      "Citi Private Bank is renowned for its global presence, cutting-edge technology, and integrated wealth management solutions tailored to the unique needs of high-net-worth individuals and families. Dynamic Management has had the privilege of servicing Citi, one of the largest American banks in the world, since 2003.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi1.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi2.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi3.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi4.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi5.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi6.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi7.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi8.jpg",
      "../assets/images/ModalDynamicImages/CITI/dynamic-citi9.jpg",
    ],
  },
  {
    ///////////////////
    //      DBS     //
    //////////////////
    id: "6",
    photo: "./assets/images/clientsWebpage/clientsWebpage-06.png",
    alt: "DBS",
    text: "DBS",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-06.png",
    modalTitle: "",
    modalDescription:
      "DBS Private Bank is recognised for its use of technology and sustainability focus. We at Dynamic are proud to partner with DBS in organizing their prestigious sporting events, using our digital solutions and event management expertise to create engaging experiences for participants and clients.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs1.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs2.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs3.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs4.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs5.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs6.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs7.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs8.jpg",
    ],
  },
  {
    ///////////////////
    //   GOLDMAN    //
    //////////////////
    id: "7",
    photo: "./assets/images/clientsWebpage/clientsWebpage-07.png",
    alt: "GoldmanSachs",
    text: "Goldman Sachs",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-07.png",
    modalTitle: "",
    modalDescription:
      "Goldman Sachs is a leading global investment banking, securities, and investment management firm that provides a wide range of financial services to a wider range of corporations and high-net-worth individuals. With a strong reputation for innovation and client service, Goldman Sachs has entrusted Dynamic with organising their prestigious golf events since 2006.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/GOLDMAN/dynamic-goldman1.jpg",
      "../assets/images/ModalDynamicImages/GOLDMAN/dynamic-goldman2.jpg",
      "../assets/images/ModalDynamicImages/GOLDMAN/dynamic-goldman3.jpg",
      "../assets/images/ModalDynamicImages/GOLDMAN/dynamic-goldman4.jpg",
      "../assets/images/ModalDynamicImages/GOLDMAN/dynamic-goldman5.jpg",
      "../assets/images/ModalDynamicImages/GOLDMAN/dynamic-goldman6.jpg",
    ],
  },
  {
    ///////////////////
    //     HSBC     //
    //////////////////
    id: "8",
    photo: "./assets/images/clientsWebpage/clientsWebpage-08.png",
    alt: "HSBC",
    text: "HSBC",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-08.png",
    modalTitle: "",
    modalDescription:
      "Dynamic has had the privilege of being a selected agency for HSBC since 2004, and has provided professional services for a wide range of events, including golf tournaments, dragon boat regattas, golf consultancy, and various other sporting events.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc1.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc2.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc3.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc4.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc5.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc6.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc7.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc8.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc9.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc10.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc11.jpg",
    ],
  },
  {
    ///////////////////
    //   Hang Seng   //
    //////////////////
    id: "9",
    photo: "./assets/images/clientsWebpage/clientsWebpage-09.png",
    alt: "HangSeng",
    text: "Hang Seng",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-09.png",
    modalTitle: "",
    modalDescription:
      "Hang Seng Bank, one of the largest financial institutions in Hong Kong, is widely recognised for its strong reputation in delivering customised and comprehensive wealth management solutions to its high-net-worth clientele. Dynamic have had the privilege in servicing the bank since 2003.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng1-2022-1.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng1-2023-1.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng2-2022-2.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng3-2021-3.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng4-2021-2.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng5-2019-1.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng6-2019-2.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng7-2019-3.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng8-2018-1.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng9-2018-2.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng10-2003-1.jpg",
      "../assets/images/ModalDynamicImages/HS/dynamic-hangseng11-2003-2.jpg",
    ],
  },
  {
    ///////////////////
    //      HKJC    //
    //////////////////
    id: "10",
    photo: "./assets/images/clientsWebpage/clientsWebpage-10.png",
    alt: "HKJC",
    text: "HKJC text",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-10.png",
    modalTitle: "",
    modalDescription:
      "The Hong Kong Jockey Club is a world-renowned horse racing organisation that is renowned for its unwavering commitment to promoting social responsibility and philanthropy. Dynamic is honored to have had the privilege of providing unique golf events to the their members since 2014, creating memorable experiences that reflect the club’s commitment to excellence.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/HKJC/dynamic-hkjc.jpg",
      "../assets/images/ModalDynamicImages/HKJC/dynamic-hkjc1.jpg",
      "../assets/images/ModalDynamicImages/HKJC/dynamic-hkjc2.jpg",
      "../assets/images/ModalDynamicImages/HKJC/dynamic-hkjc3.jpg",
      "../assets/images/ModalDynamicImages/HKJC/dynamic-hkjc4.jpg",
      "../assets/images/ModalDynamicImages/HKJC/dynamic-hkjc5.jpg",
    ],
  },
  {
    ///////////////////
    //      PwC     //
    //////////////////
    id: "11",
    photo: "./assets/images/clientsWebpage/clientsWebpage-11.png",
    alt: "PwC",
    text: "PwC Text",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-11.png",
    modalTitle: "",
    modalDescription:
      "PwC (PricewaterhouseCoopers) is a multinational professional services network of firms that provide audit, advisory, tax and consulting services to a wide range of clients.  Dynamic is proud to have been working with PwC since 2007, providing premium sporting event services that consistently exceed expectations.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/PWC/dynamic-pwc.jpg",
      "../assets/images/ModalDynamicImages/PWC/dynamic-pwc1.jpg",
      "../assets/images/ModalDynamicImages/PWC/dynamic-pwc2.jpg",
      "../assets/images/ModalDynamicImages/PWC/dynamic-pwc3.jpg",
      "../assets/images/ModalDynamicImages/PWC/dynamic-pwc4.jpg",
      "../assets/images/ModalDynamicImages/PWC/dynamic-pwc5.jpg",
    ],
  },
  {
    ///////////////////
    //HUTCHISONPORTS//
    //////////////////
    id: "12",
    photo: "./assets/images/clientsWebpage/clientsWebpage-12.png",
    alt: "HUTCHISONPORTS",
    text: "",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-12.png",
    modalTitle: "",
    modalDescription:
      "Hutchison Ports is a leading global port operator that manages and operates ports and terminals in over 25 countries.  Dynamic is proud to have been servicing them since 2007, providing unparalleled entertainment and themed parties during their annual golf days throught the years.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit1.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit2.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit3.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit4.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit5.jpg",
    ],
  },
  {
    ///////////////////
    //    McLAREN    //
    //////////////////
    id: "13",
    photo: "./assets/images/clientsWebpage/clientsWebpage-13.png",
    alt: "McLaren",
    text: "McLaren Text",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-13.png",
    modalTitle: "",
    modalDescription:
      "Dynamic is thrilled to announce McLaren as the official Hole-in-one golf partner for our selected corporate clients. Since its founding in 1963, McLaren has been celebrated for its cutting-edge technology and innovation, and our partnership represents an exciting opportunity to bring that same level of engineering excellence to the world of golf. ",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren1.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren2.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren3.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren4.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren5.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren6.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren7.jpg",
    ],
  },
  {
    ///////////////////
    //   MERCEDES   //
    //////////////////
    id: "14",
    photo: "./assets/images/clientsWebpage/clientsWebpage-14.png",
    alt: "Mercedes-Benz",
    text: "Mercedes-Benz Text",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-14.png",
    modalTitle: "",
    modalDescription:
      "Since 2008, Dynamic has proudly served as the chosen agency for the esteemed MercedesTrophy Hong Kong Golf Tournament, a testament to our unwavering commitment to excellence and proven track record of delivering high-quality event management services. ",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/MB/dynamic-mb1.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb2.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb3.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb4.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb5.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb6.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb7.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb8.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb9.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb10.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb11.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb12.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb13.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb14.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb15.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb16.jpg",
    ],
  },
  {
    ///////////////////
    //    PORSCHE   //
    //////////////////
    id: "15",
    photo: "./assets/images/clientsWebpage/clientsWebpage-15.png",
    alt: "Porsche",
    text: "Porsche Text",
    ///////////////////
    // MODAL PROPS: //
    //////////////////
    logo: "./assets/images/clientsWebpage/clientsWebpage-15.png",
    modalTitle: "",
    modalDescription:
      "The Porsche Golf Cup originated in 1988 as a small European event and has since expanded to over 30 countries worldwide. Dynamic has been proud to organise the Hong Kong events since 2015, delivering experiences that reflect the prestige and quality of the Porsche brand.",
    slideShowPix: [
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche1.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche2.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche3.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche4.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche5.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche6.jpg",
    ],
  },
];
