import styled from "styled-components";
import { motion } from "framer-motion";

export const SlideShowWrapper = styled.div`
  /* border: solid red 3px; */
  width: 420px;
  height: 280px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 420px) {
    max-width: 320px;
    height: 220px;
  }
  @media screen and (max-width: 320px) {
    max-width: 250px;
    height: 180px;
  }
`;

export const SlideShowContainer = styled.div`
  /* border: 5px green solid; */

  background-color: #05121d;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-device-width: 480px) and (orientation: landscape) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SlideShowImage = styled(motion.img)`
  /* border: 5px black solid; */

  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;

  @media only screen and (max-device-width: 480px) and (orientation: landscape) {
    width: 75%;
    height: 75%;
  }
`;

//RIGHT SIDE
export const SlideNext = styled(motion.div)`
  top: calc(50% - 20px);
  position: absolute;
  background: #fff;
  color: #000000;
  border: solid 0.8px #b0bec5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 25px;
  z-index: 2;
  right: 20px;
  :focus {
    outline: none; //Required to lose ring on mobile
  }

  @media screen and (max-width: 420px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
  @media screen and (max-width: 320px) {
    display: none;
  }
`;

//LEFT SIDE
export const SlidePrev = styled(motion.div)`
  top: calc(50% - 20px);
  position: absolute;
  background: #fff;
  color: #000000;
  border: solid 0.8px #b0bec5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 25px;
  z-index: 2;
  left: 20px;
  :focus {
    outline: none; //Required to lose ring on mobile
  }

  @media screen and (max-width: 420px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    display: none;
  }
`;

//TEXT FOR EACH SLIDE
export const SlideShowText = styled(motion.p)`
  margin-top: 5px;
  border-left: 10.8px solid #657f80;
  border-right: 10px solid #fff;
  color: "#6a6a6a";
  max-width: 400px;
  line-height: 1.5rem;
  padding-left: 10px;
  font-size: clamp(0.8rem, 1.8vw, 1rem);
  text-align: left;

  @media screen and (max-width: 420px) {
    margin: 10px;
  }
  //phone landscape mode
  @media only screen and (max-device-width: 480px) and (orientation: landscape) {
    margin-top: 0px;
    border: none;
    color: #ccc;
    max-width: 480px;
    line-height: 1.3rem;
    padding-left: 10px;
    padding-right: 10px;
    font-size: clamp(0.8rem, 1.2vw, 1rem);
    text-align: left;
  }
`;
