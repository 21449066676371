import React from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import MailTo from "./MailTo";

import {
  FooterWrapper,
  CompanyInfoWrapper,
  CompanyName,
  Address,
  MapWrapper,
  GoogleMap,
} from "./FooterStyles";

import IconEmailBox from "./svg/IconEmailBox";
import Phone from "./svg/phone";
import WhatsApp from "../WhatsAppButton/Whatsapp";
import WhatsAppLogoLink from "../WhatsAppButton/WhatsAppLogoLink";

const FooterCoMap = () => {
  return (
    <div>
      <FooterWrapper>
        <CompanyInfoWrapper>
          <CompanyName>DYNAMIC MANAGEMENT GROUP LIMITED</CompanyName>
          <Address>
            ROOM A 4TH FLOOR, SING TECK FACTORY BUILDING, 44 WONG CHUK HANG
            ROAD, WONG CHUK HANG, HONG KONG
          </Address>
          <ContactWrapper>
            <ContentBox>
              <PhoneLink href="tel:+85226633524">
                <Phone />
                <Ptag white="true">+852 2663 3524</Ptag>
              </PhoneLink>
            </ContentBox>
            <ContentBox>
              <MailTo mailto="mailto:info@dynevents.com">
                <IconEmailBox />
              </MailTo>
              <MailTo
                label="info@dynevents.com"
                mailto="mailto:info@dynevents.com"
              />
            </ContentBox>
            <ContentBox>
              <WhatsAppLogoLink />
              <WhatsApp />
            </ContentBox>
          </ContactWrapper>
        </CompanyInfoWrapper>
        <MapWrapper>
          <a
            href={"https://goo.gl/maps/chtxzMcoe8K11uNJ6"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GoogleMap />
          </a>
        </MapWrapper>
      </FooterWrapper>
    </div>
  );
};

export default FooterCoMap;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const ContentBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
`;

export const Ptag = styled(motion.div)`
  font-family: "Lora", serif;
  color: #6a6a6a;
  ${(props) =>
    props.white &&
    css`
      color: #fcfcfc;
    `}
  font-size: clamp(1rem, 2vw, 1.4rem);
`;

const PhoneLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none; /* Remove underline on hover */
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    justify-content: center;
  }
`;
