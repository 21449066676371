import styled from "styled-components";
import { motion } from "framer-motion";

export const ProfileWrapper = styled.div`
  /* border: 3px dotted black; */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 100px;
    padding-bottom: 2.8rem;
  }
`;

export const ProfileCenter = styled(motion.div)`
  /* border: 1px solid blue; */
  box-shadow: 0px 10px 10px -3px rgba(170, 170, 170, 1); //Center bottom
  background-color: lightcyan;
  cursor: pointer;
  width: 400px;
  height: 460px;
  @media screen and (max-width: 768px) {
    width: 80%;
    height: 400px;
    box-shadow: 0px 10px 10px -3px rgba(170, 170, 170, 1); //Center bottom
  }
`;

export const ProfileLeft = styled(motion.div)`
  /* border: 1px solid blue; */
  box-shadow: -3px 0px 5px 1px rgba(170, 170, 170, 1); //Left
  background-color: lightcyan;
  cursor: pointer;
  width: 350px;
  height: 400px;
  @media screen and (max-width: 768px) {
    width: 80%;
    height: 400px;
    box-shadow: 0px 10px 10px -3px rgba(170, 170, 170, 1); //Center bottom
  }
`;

export const ProfileRight = styled(motion.div)`
  /* border: 1px solid blue; */
  box-shadow: 3px 0px 5px 1px rgba(170, 170, 170, 1); //Right
  background-color: lightcyan;
  cursor: pointer;
  width: 350px;
  height: 400px;
  @media screen and (max-width: 768px) {
    width: 80%;
    height: 400px;
    box-shadow: 0px 10px 10px -3px rgba(170, 170, 170, 1); //Center bottom
  }
`;
