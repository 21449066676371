//////////////////////
// DIGITAL SERVICES //
/////////////////////

export const ServiceOne = {
  reverse: false, //True = Photo on LEFT, False= Photo on RIGHT
  i: "", //0 or "inverse" = true = WHITE Theme  |  1 or "" = false = DARK Theme
  position: "false", //"false" = justify left, "", justify center
  id: "services",
  headline: "DIGITAL SERVICES",
  description:
    "Our comprehensive end-to-end digital solutions offer a range of advanced features, including touch-less registration via QR codes, paperless events with all necessary details integrated into our event web-based apps, and professional booking services to facilitate the hosting of unique and successful events.  Our digital solutions enable us to significantly reduce the environmental impact of paper waste, which in turn helps to lower our company's carbon footprint and contributes to a more sustainable future for our planet.",
  buttonLabel: "More Info",
  img: "./assets/images/ModalDynamicImages/DIGITALSERVICES/Dynamic-digitalservices0.jpg",
  alt: "Digital Services",

  ///////////////////
  // MODAL PROPS: //
  //////////////////
  inverse: 1,
  logo: "",
  modalTitle: "DIGITALIZATION",
  modalDescription: "",
  slideShowPix: [
    //1 SLIDE
    "./assets/images/ModalDynamicImages/DIGITALSERVICES/Dynamic-digitalservices1.jpg",
    //2 SLIDE
    "./assets/images/ModalDynamicImages/DIGITALSERVICES/Dynamic-digitalservices2.jpg",
    //3 SLIDE
    "./assets/images/ModalDynamicImages/DIGITALSERVICES/Dynamic-digitalservices3.jpg",
    //4 SLIDE
    "./assets/images/ModalDynamicImages/DIGITALSERVICES/Dynamic-digitalservices4.jpg",
  ],
  slidesShowDiscription: [
    //1 TEXT
    "Using QR codes in event registration is faster, more accurate, provides better data tracking and analysis.  It also can save costs compared to traditional paper-based methods while contributing to an eco-friendly environment",

    //2 TEXT
    "Dynamic provide web based apps with numerous advantages, including easy access to event rundowns, personalised schedules for individual clients and instant private photos on a centralised platform. ",
    //3 TEXT
    "The use of an event web app results in a reduction of paper usage, thereby minimising carbon footprint and promoting environmental sustainability.  Guests will also appreciate a private site for personal viewing of event photos.",
    //4 TEXT
    "By providing a one-stop-shop for all event-related information, an event web app enhances the overall experience for attendees and promotes more efficient and eco-friendly event management.",
  ],
};

//////////////////////
//  GOLF SERVICES  //
/////////////////////
export const ServiceTwo = {
  reverse: true, //image left Flex-Direction
  i: "inverse", //0 or "inverse" = true = WHITE Theme  |  1 or "" = false = DARK Theme
  position: "false", //"false" = justify left, "", justify center
  id: "golfservices",
  headline: "GOLF SERVICES",
  description:
    "As a premier industry leader in organizing exquisite corporate golf events throughout Asia, Dynamic is renowned for its unrivalled expertise and dedication to excellence. With a deep understanding of the region's growing demand for top-quality events, we provide highly skilled and experienced staff to ensure seamless operations, thereby allowing you to focus on your clients and enjoy a truly unforgettable day on the course.",
  buttonLabel: "More info",
  img: "./assets//hsbcflag800w_fast.gif",
  alt: "golfservices",

  ///////////////////
  // MODAL PROPS: //
  //////////////////
  inverse: "inverse",
  logo: "",
  modalTitle: "GOLF SERVICES",
  modalDescription: "Modal Description of digital services....",
  slideShowPix: [
    //1 SLIDE
    "../assets/images/ModalDynamicImages/AIG/dynamic-aig2.jpg",
    //2 SLIDE
    "../assets/images/ModalDynamicImages/GOLFSERVICES/dynamic-golfservices.jpg",
    //3 SLIDE
    "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot1.jpg",
    //4 SLIDE
    "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc3.jpg",
  ],
  slidesShowDiscription: [
    //1 TEXT
    "Client entertainment and relationship building are essential for any business looking to establish strong, long-lasting connections with their clients. Golf has long been recognised as one of the most effective ways to achieve this!",
    //2 TEXT
    "Rely on Dynamic, a company of over 20 years of expertise, to handle all aspects of the golf event, allowing you to focus on entertaining clients and building relationships while we assist in creating a truly memorable experience.",
    //3 TEXT
    "Hiring a golf professional or trick shot artist can add excitement and entertainment to any golf tournament, providing a unique and unforgettable experience for participants and spectators alike.",
    //4 TEXT
    "Adding a hole-in-one prize at a golf day can add a unique and exciting element to the event creating a buzz among participants. Suggested prizes can include a luxury car, an exotic vacation, or a major cash prize!",
  ],
};

//////////////////////
//  TEAM BUILDING   //
/////////////////////
export const ServiceThree = {
  reverse: false, //image right Flex-Direction
  i: "", //0 or "inverse" = true = WHITE Theme  |  1 or "" = false = DARK Theme
  position: "false", //"false" = justify left, "", justify center
  id: "teambuilding",
  headline: "TEAM BUILDING",
  description:
    "Dynamic is pleased to announce our long-standing partnership with Typhoon8 Limited to offer unique and engaging Dragon Boat Races as Team Building events. Our comprehensive packages include all necessary equipment and safety measures, team gear and equipment, rental of boats, and guaranteed bookings for practices. Our weekly training sessions are led by highly qualified dragon boat racers and paddling enthusiasts, ensuring a fun and rewarding experience for all participants.",
  buttonLabel: "More info",
  img: "./assets/dynamic-dragonboatraces.gif",
  alt: "teambuilding",

  ///////////////////
  // MODAL PROPS: //
  //////////////////
  inverse: "inverse",
  logo: "../assets/images/clientsWebpage/clientsWebpage-02.png",
  modalTitle: "Team Building",
  modalDescription: "Modal Description of digital services....",
  slideShowPix: [
    //1 SLIDE
    "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat1.jpg",
    //2 SLIDE
    "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc6.jpg",
    //3 SLIDE
    "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc5.jpg",
    //4 SLIDE
    "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc7.jpg",
  ],
  slidesShowDiscription: [
    //1 TEXT
    "Dragon boating is a great way to improve teamwork and communication skills. By working together towards a common goal, team members develop a sense of camaraderie and shared purpose!",
    //2 TEXT
    "Through our partnership with Lister Woo, Founder of Typhoon8 Limited, a leading Asian paddling brand, Dynamic is proud to offer a range of specialised coaching services and private dragon boat races. ",
    //3 TEXT
    "Our Dragon Boat Regattas offer private facilities with all necessary equipment, safety measures, and gear. We provide a premium experience that is safe, enjoyable, and tailored to your needs.",
    //4 TEXT
    "Dynamic will provide experienced Dragon Boat trainers who offer a focus on technique, teamwork, and safety, starting with dry land drills and ensuring participants have fun without exhaustion.",
  ],
};

/////////////////////////
// PREMIUM PRODUCTION //
///////////////////////
export const ServiceFour = {
  reverse: true, //image left Flex-Direction
  i: 1, //0 or "inverse" = true = WHITE Theme  |  1 or "" = false = DARK Theme
  position: "false", //"false" = justify left, "", justify center
  id: "products",
  headline: "PREMIUMS",
  description:
    "Selecting the right gifts for clients can significantly impact their perception of your company, making it essential to choose high-quality premiums that leave a lasting impression. At Dynamic, we pride ourselves on offering an extraordinary range of premium products carefully curated from trusted vendors and distinguished brands. From luxury goods to bespoke items, our premium selection is sure to impress and leave a memorable impression on your clients..",
  buttonLabel: "More info",
  img: "../assets/images/ModalDynamicImages/PREMIUMS/dynamic-premiums1.jpg",
  alt: "products",

  //linkTo: "/products",//LINK ahref

  ///////////////////
  // MODAL PROPS: //
  //////////////////
  inverse: "inverse",
  logo: "../assets/images/clientsWebpage/clientsWebpage-02.png",
  modalTitle: "Premiums",
  modalDescription: "Modal Description of digital services....",
  slideShowPix: [
    //1 SLIDE
    "../assets/images/ModalDynamicImages/PREMIUMS/dynamic-premiums1.jpg",
    //2 SLIDE
    "../assets/images/ModalDynamicImages/PREMIUMS/dynamic-premiums2.jpg",
    //3 SLIDE
    "../assets/images/ModalDynamicImages/PREMIUMS/dynamic-premiums3.jpg",
    //4 SLIDE
    "../assets/images/ModalDynamicImages/PREMIUMS/dynamic-premiums4.jpg",
  ],
  slidesShowDiscription: [
    //1 TEXT
    "Whether you're hosting a corporate golf tournament or a charity event, our company can help you take it to the next level by providing a selection of premium, high-end golf brands that are sure to impress your players and leave a lasting impression.",
    //2 TEXT
    "Selecting the right products for a sporting event, particularly in golf, is crucial to creating a memorable and enjoyable experience for participants, as the quality and relevance of the products can greatly impact their impression of the event.",
    //3 TEXT
    "When it comes to selecting premium gifts for a golf event, quality is an absolute must. High-end brands and products not only convey a sense of luxury and exclusivity, but they also demonstrate a commitment to excellence, attention to detail, and a desire to provide the very best for your clients.",
    //4 TEXT
    "Packaging can make all the difference when it comes to presenting premium golf gifts. A beautifully packaged gift not only enhances the overall presentation, but it also adds to the perceived value and quality of the product, making it even more memorable for clients.",
  ],
};
