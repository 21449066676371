import React from "react";
import { Menu, MenuItem } from "./NavLinksStyles";

import { Link } from "react-scroll";

const NavLinks = (props) => {
  //props to accept additional parameters i.e. close navbar tab in mobile once link is clicked
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };

  return (
    <>
      <Menu>
        <MenuItem
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.3 }}
        >
          <Link
            onClick={() => props.isMobile && props.closeMobileMenu()}
            style={{ cursor: "pointer" }}
            spy={true}
            duration={500}
            smooth={true}
            exact="true"
            offset={-79}
            to={"hero"} //indicated on Hero.js id="hero"
          >
            HOME
          </Link>
        </MenuItem>

        <MenuItem
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.4 }}
        >
          <Link
            onClick={() => props.isMobile && props.closeMobileMenu()}
            style={{ cursor: "pointer" }}
            spy={true}
            duration={500}
            smooth={true}
            exact="true"
            offset={-79}
            to={"services"} //indicated on Services.js id="services"
          >
            SERVICES
          </Link>
        </MenuItem>

        <MenuItem
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.5 }}
        >
          <Link
            onClick={() => props.isMobile && props.closeMobileMenu()}
            style={{ cursor: "pointer" }}
            spy={true}
            duration={500}
            smooth={true}
            exact="true"
            offset={-79}
            to={"products"} //indicated on Products.js id="products"
          >
            PRODUCTS
          </Link>
        </MenuItem>

        <MenuItem
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.6 }}
        >
          <Link
            onClick={() => props.isMobile && props.closeMobileMenu()}
            style={{ cursor: "pointer" }}
            spy={true}
            duration={500}
            smooth={true}
            exact="true"
            offset={-79}
            to={"projects"} //indicated on Projects.js id="projects"
          >
            PROJECTS
          </Link>
        </MenuItem>

        <MenuItem
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 0.8 }}
        >
          <Link
            onClick={() => props.isMobile && props.closeMobileMenu()}
            style={{ cursor: "pointer" }}
            spy={true}
            duration={500}
            smooth={true}
            exact="true"
            offset={-79}
            to={"team"} //indicated on Team.js id="team"
          >
            TEAM
          </Link>
        </MenuItem>

        <MenuItem
          initial={animateFrom}
          animate={animateTo}
          transition={{ delay: 1 }}
        >
          <a
            href="https://dynevents.com/index_c.html"
            rel="noopener norefferrer"
          >
            中文
          </a>
        </MenuItem>
      </Menu>
    </>
  );
};

export default NavLinks;
