import { Container, SectionWrapper, EventHeading } from "../../globalStyles";
//APP PAGE CONTENT ***Note also requires useState
import { useInView } from "react-intersection-observer"; //Modal to launch once in view
//MasonryGrid functionality requires state
import React, { useEffect, useState } from "react";
//Masonry Grid Visual Effects
import { useAnimation } from "framer-motion";

//Modal Data
import { modalData } from "../../data/ProjectsData";

//MODAL COMPONENT
import Modal from "../Modal/Modal";

//MasonryGrid Styled Component
import {
  MasonryGrid, //MasonryGrid
  EventImageWrapper, //Wrapper of each image in MasonryGrid
  Image, //Masonary Grid Image
} from "./ProjectsStyle";

//Modal Styled Components:
import {
  Logo, //Logo inside Modal
  // ContentHeading, //Title inside Modal
  Subtitle, //Subtitle inside Modal
} from "../Modal/ModalStyles";

//Modal Component:
import ModalSlideShow from "../Modal/ModalSlideShow";

const Projects = ({ inverse, mt }) => {
  //MODAL + FRAMER-MOTION FUNCTIONS
  const animation = useAnimation(); //framer-motion

  //State for MODAL
  const [showModal, setShowModal] = useState(false);

  //State for Selected IMAGE property used for Masonry Grid
  //Used to pass in image in MODAL
  const [selectedData, setselectedData] = useState(0);

  //ref is for react-intersection-observer
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  //react-intersection-observer
  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        scale: 1,
      });
    }
  }, [inView, animation]);

  //image selected then open same image in index option 1
  const handleClick = (index) => {
    //Option1 single image opens
    setShowModal(true);
    //Option2
    setselectedData(index); //Option2 -> Indicate what project you clicked then sets array index item, then goes to JSON Object and selects the index of item.
  };

  return (
    <div id="projects">
      {/* /////////////////////////////////////////////////// */}
      {/* //               PROJECT COMPONENT               // */}
      {/* /////////////////////////////////////////////////// */}
      <SectionWrapper inverse={false}>
        <Container>
          <EventHeading
            inverse={""}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.3 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            OUR PROJECTS
          </EventHeading>

          <MasonryGrid ref={ref}>
            {modalData.map((item, index) => (
              <EventImageWrapper
                animate={animation}
                initial={{
                  opacity: 0,
                  scale: 0.3,
                }}
                transition={{ duration: 0.8 }}
                className={item.class}
                key={index}
                onClick={() => handleClick(index)}
              >
                <Image src={item.img}></Image>
              </EventImageWrapper>
            ))}
          </MasonryGrid>

          {/* /////////////////////////////////////////////////// */}
          {/* //               MODAL COMPONENT                 // */}
          {/* /////////////////////////////////////////////////// */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Logo
              src={modalData[selectedData].logo}
              alt={modalData[selectedData].alt}
            />

            <ModalSlideShow slides={modalData[selectedData]} />

            {/* 
    //FIX:  Received `true` for a non-boolean attribute `inverse` + 'mt'.
    //FIX: replace 'inverse' with 'inverse = { +true} ' 
    //FIX: replace 'mt' with 'mt={+true}
 */}
            {/* <ContentHeading inverse={+true}>
              {modalData[selectedData].title}
            </ContentHeading> */}

            <Subtitle
              //Framer Motion Fade in
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: ".8", delay: ".55" }}
            >
              {modalData[selectedData].description}
            </Subtitle>
          </Modal>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Projects;
