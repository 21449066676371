import React, { useState } from "react"; //used for modal

import {
  ProfileBackgroundImage,
  TitleContainer,
  TitleBox,
  TitleText,
  NameFont,
  TitleFont,
} from "./TeamContentStyles";

import { ModalImage } from "../../ModalTeam/TeamModalStyles";

///////////////
//  MODAL   //
/////////////
import TeamModal from "../../ModalTeam/TeamModal";
//Modal Styled-Componentns:
import { ContentHeading, ContentText } from "../../ModalTeam/TeamModalStyles";

export const TeamContent = ({
  id,
  name,
  title,
  img,
  //MODAL PROPS
  modalp1,
  modalp2,
  modalp3,
  modalp4,
  modalp5,
  modalName,
  linkImage,
}) => {
  //USE STATE = MODAL OPEN & CLOSE
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {/* **********************
            PROFILE PHOTOS 
        ********************** */}
      <ProfileBackgroundImage
        img={img} //Profile Image Prop
        onClick={() => {
          setShowModal(true);
          id = { id };
        }}
      >
        <TitleContainer>
          <TitleBox>
            <TitleText
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: [0, 1],
              }}
              viewport={{ once: false, amount: 0.5 }}
              transition={{ duration: ".8", ease: "easeInOut" }}
            >
              <NameFont>{name}</NameFont>
              <TitleFont>{title}</TitleFont>
            </TitleText>
          </TitleBox>
        </TitleContainer>
      </ProfileBackgroundImage>

      {/* **********************
             MODAL COMPONENT 
        ********************** */}
      <TeamModal show={showModal} onHide={() => setShowModal(false)}>
        <ModalImage
          backgroundimage={linkImage}
          animate={{
            scale: [1, 1.08, 1],
          }}
          transition={{
            duration: 0.8,
          }}
        />
        <ContentHeading>{modalName}</ContentHeading>
        <ContentText>
          {modalp1} <br />
          <br />
          {modalp2} <br />
          <br />
          {modalp3} <br />
          <br />
          {modalp4} <br />
          <br />
          {modalp5} <br />
          <br />
        </ContentText>
      </TeamModal>
    </>
  );
};
