import React from "react";
import NavBar from "../components/Navbar/Navbar";
import Hero from "../components/Hero/Hero";
import Services from "../components/Services/Services";
import Projects from "../components/Projects/Projects";
import Team from "../components/Team/Team";
import Footer from "../components/Footer/Footer";
import Clients from "../components/Clients/Clients";

function Home() {
  return (
    <>
      <NavBar />
      <Hero id="hero" />
      <Clients />
      <Services id="services" />
      <Projects id="projects" />
      <Team id="team" />
      <Footer id="footer" />
    </>
  );
}

export default Home;
