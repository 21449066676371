import styled from "styled-components";
import { motion } from "framer-motion";

export const TitleSvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100vw;
`;

//Framer-Motion on SVG
export const Svg = styled(motion.svg)`
  /* Note: max-width will NOT work in Safari for SVG */
  width: 600px;
  overflow: visible;
  stroke: #fff; //Line Color
  stroke-width: 1.2;
  stroke-linejoin: round;
  stroke-linecap: round;
  z-index: 1;
  margin-top: 55vh;

  @media screen and (max-width: 960px) {
    width: 500px;
    margin-top: 52vh;
  }

  @media screen and (max-width: 768px) {
    width: 480px;
    margin-top: 51vh;
  }

  @media screen and (max-width: 600px) {
    width: 400px;
    margin-top: 49vh;

    @media screen and (max-width: 420px) {
      width: 320px;
      margin-top: 48vh;
    }

    @media screen and (max-width: 320px) {
      width: 240px;
      margin-top: 45vh;
    }
  }
`;
