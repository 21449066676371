import React from "react";
import WhatsAppLogo from "../Footer/svg/WhatsAppLogo";

function WhatsAppLogoLink() {
  const part1 = "+852";
  const part2 = "9455";
  const part3 = "1355";
  const phoneNumber = `${part1}${part2}${part3}`; // Concatenate the parts

  // Validate phone number format (basic validation)
  const isValidPhoneNumber = (number) => /^\+\d{1,3}\d{4,14}$/.test(number);

  if (!isValidPhoneNumber(phoneNumber)) {
    console.error("Invalid phone number format");
    return null;
  }

  const preFilledMessage = encodeURIComponent(
    "Hello, I'd like to inquire about your services."
  );
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${preFilledMessage}`;

  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
      <WhatsAppLogo />
    </a>
  );
}

export default WhatsAppLogoLink;
