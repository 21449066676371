import styled from "styled-components";
import { motion } from "framer-motion";

//////////////////////////
//FOOTER CONTENT HOLDER //
//////////////////////////
export const FooterWrapper = styled.div`
  /* border: solid red 1px; */
  color: #fcfcfc; //font color
  //Flex-Box
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;

  @media screen and (max-width: 900px) {
    /* border: blue solid 2px; */
    //Flex-Box
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const CompanyInfoWrapper = styled.div`
  /* border: solid yellow 2px; */
  flex-basis: 60%;
`;

export const CompanyName = styled(motion.div)`
  color: #fcfcfc;
  font-size: clamp(2rem, 4vw, 2.5rem);
  line-height: 1em;
  letter-spacing: 0.05ch;
  font-weight: 800;
  text-transform: uppercase;
  text-align: left;

  @media screen and (max-width: 900px) {
    font-size: clamp(3rem, 4vw, 3.5rem);
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    font-size: clamp(2.5rem, 4vw, 3rem);
    text-align: center;
  }
  @media screen and (max-width: 400px) {
    font-size: clamp(1.8rem, 3vw, 2.3rem);
  }
`;

export const Address = styled.div`
  color: #fcfcfc;
  font-family: "Lora", serif;
  font-weight: 500;
  line-height: clamp(2rem, 2vw, 3rem);
  font-size: clamp(1rem, 2vw, 1.4rem);
  padding-top: clamp(1rem, 2vw, 2.5rem);
  padding-bottom: clamp(1rem, 2vw, 2rem);
  text-align: left;

  @media screen and (max-width: 900px) {
    line-height: 1.8rem;
    font-size: clamp(1rem, 2vw, 1.3rem);
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    line-height: 2rem;
    font-size: clamp(1.1rem, 2vw, 1.4rem);
    padding: 1em;
  }
  @media screen and (max-width: 500px) {
    line-height: 2.2rem;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    padding: 0.5em;
  }
  @media screen and (max-width: 320px) {
    line-height: 1.4rem;
    font-size: clamp(1rem, 2vw, 1.2rem);
  }
`;

export const MapWrapper = styled.div`
  /* border: blue 3px solid; */
  margin-left: 2rem;
  flex-basis: 40%;

  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;

export const GoogleMap = styled.div`
  display: flex;
  border: solid white 1px;
  width: 450px;
  height: 250px;
  border-radius: 8px;

  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
    url("./assets/images/GoogleMaps/map.gif");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  cursor: pointer;

  @media screen and (max-width: 1000px) {
    height: 350px;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (max-width: 900px) {
    width: 600px;
    height: 300px;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (max-width: 768px) {
    width: 500px;
    height: 250px;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (max-width: 600px) {
    width: 450px;
    height: 250px;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (max-width: 500px) {
    width: 400px;
    height: 300px;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (max-width: 450px) {
    width: 300px;
    height: 300px;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (max-width: 320px) {
    width: 250px;
    height: 250px;
    margin: 1rem 1rem 1rem 1rem;
  }
  @media screen and (max-width: 300px) {
    display: none;
  }
`;

export const FooterCopyright = styled.div`
  font-family: "Lora", serif;
  color: #fcfcfc;
  font-weight: 500;
  line-height: clamp(2rem, 2vw, 3rem);
  font-size: clamp(1rem, 2vw, 1.4rem);
  padding-top: clamp(1rem, 2vw, 2.5rem);
  padding-bottom: clamp(1rem, 2vw, 2rem);
  border-top: 0.5px #ccc solid;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 500px) {
    text-align: center;
    padding-top: 1rem;
    margin: 1rem 0rem 1rem 0rem;
  }
`;
