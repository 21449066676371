import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";

import {
  SlideShowWrapper,
  SlideShowContainer,
  SlideShowImage,
  SlideNext,
  SlidePrev,
  SlideShowText,
} from "./ModalSlideShowStylesServices";

//Framer-Motion Variants for slideshow
const variants = {
  enter(direction) {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    x: 1000,
    opacity: 0,
  },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const ModalSlideShow = ({ slides, slidestext }) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const imageIndex = wrap(0, slides.slideShowPix.length, page);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };
  return (
    <>
      <SlideShowWrapper>
        <SlideShowContainer>
          <AnimatePresence initial={false} custom={direction}>
            <SlideShowImage
              key={page}
              src={slides.slideShowPix[imageIndex]}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            />
          </AnimatePresence>

          <SlideNext
            whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
            whileTap={{ scale: 0.9 }}
            onClick={() => paginate(1)}
          >
            {">"}
          </SlideNext>
          <SlidePrev
            whileHover={{ scale: 1.2, transition: { duration: 0.5 } }}
            whileTap={{ scale: 0.9 }}
            onClick={() => paginate(-1)}
          >
            {"<"}
          </SlidePrev>
        </SlideShowContainer>
      </SlideShowWrapper>
      <SlideShowText
        //Framer Motion Fade in
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: ".8", delay: "0.3" }}
      >
        {slidestext.slidesShowDiscription[imageIndex]}
      </SlideShowText>
    </>
  );
};

export default ModalSlideShow;
