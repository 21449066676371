export const images = [
  {
    src: "../assets/dynamicevents1.jpg",
    alt: "DynamicHeroImage1",
  },
  {
    src: "../assets/dynamicevents2.jpg",
    alt: "DynamicHeroImage2",
  },
  {
    src: "../assets/dynamicevents3.jpg",
    alt: "DynamicHeroImage3",
  },
  {
    src: "../assets/dynamicevents4.jpg",
    alt: "DynamicHeroImage4",
  },
  {
    src: "../assets/dynamicevents5.jpg",
    alt: "DynamicHeroImage5",
  },
];
