//*** MasonryGrid COVER Image Style Options via class: big, tall, wide, empty class is default

export const modalData = [
  //////////////////////
  //       ONE-MB    //
  ////////////////////
  //
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-14.png",
    title: "",
    //MODAL IMAGE
    img: "../assets/images/ModalDynamicImages/MB/dynamic-mb.jpg",
    class: "big",
    alt: "MercedesTrophy",
    description:
      "Since 2008, Dynamic has proudly served as the chosen agency for the esteemed MercedesTrophy Hong Kong Golf Tournament, a testament to our unwavering commitment to excellence and proven track record of delivering high-quality event management services. ",

    //MODAL SLIDESHOW
    slideShowPix: [
      "../assets/images/ModalDynamicImages/MB/dynamic-mb1.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb2.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb3.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb4.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb5.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb6.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb7.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb8.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb9.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb10.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb11.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb12.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb13.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb14.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb15.jpg",
      "../assets/images/ModalDynamicImages/MB/dynamic-mb16.jpg",
    ],
  },

  //////////////////////
  //     TWO-AP      //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-02.png",

    title: "",
    img: "../assets/images/ModalDynamicImages/AP/dynamic-ap.jpg",
    alt: "Audemars Piguet",
    class: "tall",
    description:
      "The Audemars Piguet Golf Trophy is a prestigious golf tournament that is held annually at various golf courses around the world and is known for attracting top golfers and celebrities. Dynamic organized the highly acclaimed event in Hong Kong in 2017, showcasing our expertise in event management and commitment to excellence.",

    slideShowPix: [
      "../assets/images/ModalDynamicImages/AP/dynamic-ap1.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap2.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap3.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap4.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap5.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap6.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap7.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap8.jpg",
      "../assets/images/ModalDynamicImages/AP/dynamic-ap9.jpg",
    ],
  },

  //////////////////////
  //    THREE-HSBC   //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-08.png",
    title: "",
    img: "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc.jpg",
    alt: "HSBC Private Bank",
    description:
      "Dynamic has had the privilege of being a selected agency for HSBC since 2004, and has provided professional services for a wide range of events, including golf tournaments, dragon boat regattas, golf consultancy, and various other sporting events.",
    // class: "big", //Option 'tall', 'wide' or 'big'
    slideShowPix: [
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc1.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc2.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc3.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc4.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc5.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc6.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc7.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc8.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc9.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc10.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc11.jpg",
    ],
  },

  //////////////////////
  //   FOUR-MCLAREN  //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-13.png",

    title: "",

    img: "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren.jpg",

    alt: "McLaren",
    description:
      "Dynamic is thrilled to announce our partnership with McLaren as the official Hole-in-one golf partner for our selected corporate clients. As a world-renowned brand with a rich history in motorsport and engineering, McLaren is the perfect partner for this exclusive offering. Since its founding in 1963, McLaren has been celebrated for its cutting-edge technology and innovation, and its partnership with Dynamic represents an exciting opportunity to bring that same level of engineering excellence to the world of golf.",
    class: "wide", //Option 'tall', 'wide' or 'big'

    slideShowPix: [
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren1.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren2.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren3.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren4.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren5.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren6.jpg",
      "../assets/images/ModalDynamicImages/MCLAREN/dynamic-mclaren7.jpg",
    ],
  },

  //////////////////////
  //   FIVE-DBS      //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-06.png",
    title: "",
    img: "../assets/images/ModalDynamicImages/DBS/dynamic-dbs.jpg",
    alt: "DBS Private Bank Golf Day",
    //class: "tall", //Option 'tall', 'wide' or 'big'
    description:
      "DBS Private Bank is recognised for its use of technology and sustainability focus. We at Dynamic are proud to partner with DBS in organizing their prestigious sporting events, using our digital solutions and event management expertise to create engaging experiences for participants and clients.",

    slideShowPix: [
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs1.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs2.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs3.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs4.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs5.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs6.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs7.jpg",
      "../assets/images/ModalDynamicImages/DBS/dynamic-dbs8.jpg",
    ],
  },

  //////////////////////
  //      SIX-HIT    //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-12.png",
    title: "Title FIVE",
    img: "../assets/images/ModalDynamicImages/HIT/dynamic-hit.jpg",
    alt: "Project Five Image",
    description:
      "Hutchison Ports is a leading global port operator that manages and operates ports and terminals in over 25 countries.  Dynamic is proud to have been servicing them since 2007, providing unparalleled entertainment and themed parties during their annual golf days throught the years.",
    // class: "big", //Option 'tall', 'wide' or 'big'

    slideShowPix: [
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit1.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit2.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit3.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit4.jpg",
      "../assets/images/ModalDynamicImages/HIT/dynamic-hit5.jpg",
    ],
  },

  //////////////////////
  // SEVEN-TRICKSHOT //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-18.png",
    title: "Golf Talent",
    img: "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot.jpg",
    alt: "PGAPROS",
    description:
      "When it comes to organizing successful golf events, having the right talent can make all the difference.  Dynamic specialises in providing professional golf entertainers and players to make each event unique and memorable.",
    class: "tall", //Option 'tall', 'wide' or 'big'

    slideShowPix: [
      // "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot0.jpg",
      "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot1.jpg",
      "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot2.jpg",
      "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot3.jpg",
      "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot4.jpg",
      "../assets/images/ModalDynamicImages/TRICKSHOT/dynamic-trickshot5.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc1.jpg",
      "../assets/images/ModalDynamicImages/HSBC/dynamic-hsbc2.jpg",
    ],
  },

  //////////////////////
  //  EIGHT-ASHWORTH //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-16.png",
    title: "Ashworth",
    img: "../assets/images/ModalDynamicImages/JUSTINROSE/dynamic-justinrose.jpg",
    alt: "Justing Rose Ashworth",
    description:
      "Dynamic had the esteemed privilege of organising a distinguished event for Ashworth's global brand ambassador, the illustrious US Open Champion, Justin Rose.",
    class: "tall", //Option 'tall', 'wide' or 'big'

    slideShowPix: [
      "../assets/images/ModalDynamicImages/JUSTINROSE/dynamic-justinrose1.jpg",
      "../assets/images/ModalDynamicImages/JUSTINROSE/dynamic-justinrose2.jpg",
      "../assets/images/ModalDynamicImages/JUSTINROSE/dynamic-justinrose3.jpg",
      "../assets/images/ModalDynamicImages/JUSTINROSE/dynamic-justinrose4.jpg",
    ],
  },

  //////////////////////
  //  NINE-PORSCHE   //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-15.png",
    title: "",
    img: "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche.jpg",
    alt: "Porsche Golf Day",
    description:
      "The Porsche Golf Cup originated in 1988 as a small European event and has since expanded to over 30 countries worldwide. Dynamic has been proud to organise the Hong Kong events since 2015, delivering experiences that reflect the prestige and quality of the Porsche brand.",
    // class: "big", //Option 'tall', 'wide' or 'big'

    slideShowPix: [
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche1.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche2.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche3.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche4.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche5.jpg",
      "../assets/images/ModalDynamicImages/PORSCHE/dynamic-porsche6.jpg",
    ],
  },

  //////////////////////
  //  TEN-CENTURION  //
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-04.png",
    title: "",
    img: "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion.jpg",
    alt: "Centurion",
    description:
      "The Centurion Card, also known as the Amex Black Card, is an exclusive and prestigious invitation-only charge card that offers a range of elite benefits. Dynamic has had the privilege of being the chosen agency to help organise Centurion’s annual golf day for their clients since 2017, and we are honoured to continue providing our professional services to such a distinguished and esteemed brand..",
    // class: "big", //Option 'tall', 'wide' or 'big'

    slideShowPix: [
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion1.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion2.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion3.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion4.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion5.jpg",
      "../assets/images/ModalDynamicImages/CENTURION/dynamic-centurion6.jpg",
    ],
  },

  //////////////////////
  //   ELEVEN-AIG    //
  ////////////////////

  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-01.png",

    title: "",
    img: "../assets/images/ModalDynamicImages/AIG/dynamic-aig.jpg",
    alt: "AIG",
    description:
      "AIG insurance is known for its global reach, diverse product offerings, financial strength, innovation, and commitment to customer service.  Dynamic has been providing services to AIG since 2007, specialising in sports management and production sourcing with a focus on delivering high-quality and innovative solutions throughout the years.",

    class: "wide", //Option 'tall', 'wide' or 'big'

    slideShowPix: [
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig1.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig2.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig3.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig4.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig5.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig6.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig7.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig8.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig9.jpg",
      "../assets/images/ModalDynamicImages/AIG/dynamic-aig10.jpg",
    ],
  },

  //////////////////////
  //TWELVE-DRAGONBOAT//
  ////////////////////
  {
    logo: "../assets/images/clientsWebpage/clientsWebpage-17.png",
    title: "",
    img: "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat.jpg",
    alt: "Covestro Dragonboat training",
    description:
      "Dynamic is thrilled to extend its warmest congratulations to the Covestro dragon boat team on their remarkable success in the local races. The team's tireless dedication and hard work, combined with their exceptional teamwork and execution, have been rewarded with a well-deserved trophy, which now proudly adorns their office. .",
    // class: "big", //Option 'tall', 'wide' or 'big'
    slideShowPix: [
      "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat1.jpg",
      "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat3.jpg",
      "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat4.jpg",
      "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat5.jpg",
      "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat6.jpg",
      "../assets/images/ModalDynamicImages/DRAGONBOAT/dynamic-dragonboat2.jpg",
    ],
  },
];
