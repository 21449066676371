import { SectionWrapper, Container, EventHeading } from "../../globalStyles";
import React, { useState } from "react"; //used for modal
import ButtonMobile from "../ButtonMobile/ButtonMobile"; //Component in button folder + using globalStyles styled-components called 'ButtonTouch'

//Styled-Componenents
import {
  ContentRow,
  ContentColumn,
  TextWrapper,
  ImgWrapper,
  Img,
  SubtitleText, //Changed from Subtitle to SubtitleText as it clashes with modal
  zoomVariants,
  slideVariants,
} from "./ServicesContentStyles";

///////////////
//  MODAL   //
/////////////
import Modal from "../ModalServices/Modal";

//Modal Styled Components:
import {
  // Logo, //Logo inside Modal
  ContentHeading, //Title inside Modal
} from "../ModalServices/ModalStyles";

//Modal Component:
import ModalSlideShow from "../ModalServices/ModalSlideShow";

//////////////////////////////////////////
// ServicesContent COMPONENT with PROPS //
/////////////////////////////////////////
export const ServicesContent = ({
  reverse,
  i, //inverse attribute
  id,
  headline,
  position,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  //MODAL PROPS
  inverse,
  // logo, //REMOVED
  modalTitle,
  // modalDescription, //Static one line
  slideShowPix,
  slidesShowDiscription,
}) => {
  //USE STATE = MODAL OPEN & CLOSE
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <SectionWrapper inverse={i} id={id}>
        <Container>
          <ContentRow reverse={reverse}>
            <ContentColumn
              variants={zoomVariants}
              whileInView={zoomVariants.WhileInView}
              viewport={zoomVariants.Viewport}
            >
              <TextWrapper>
                <EventHeading position={position} inverse={i}>
                  {headline}
                </EventHeading>
                <SubtitleText inverse={i}>{description}</SubtitleText>
                <ButtonMobile
                  primary={primary}
                  //DISPLAY MODAL
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {buttonLabel}
                </ButtonMobile>
              </TextWrapper>
            </ContentColumn>

            <ContentColumn
              variants={slideVariants}
              whileInView={slideVariants.WhileInView}
              viewport={slideVariants.Viewport}
            >
              <ImgWrapper>
                <Img
                  src={img}
                  alt={alt}
                  onClick={() => {
                    setShowModal(true);
                  }}
                />
              </ImgWrapper>
            </ContentColumn>
          </ContentRow>
        </Container>
      </SectionWrapper>
      {/* **********************
            MODAL COMPONENT 
      ********************** */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {/* <Logo src={logo} alt={alt} /> */}

        <ContentHeading inverse={inverse}>{modalTitle}</ContentHeading>

        <ModalSlideShow
          slides={{ slideShowPix }}
          slidestext={{ slidesShowDiscription }}
        />
      </Modal>
    </>
  );
};
