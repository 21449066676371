import React from "react";

import {
  Container,
  SectionWrapper,
  EventHeading,
  Ptag,
} from "../../globalStyles";

//DATA
import { natalieLeft, rickCenter, listerRight } from "../../data/TeamData";

//Child Component for each profile
import { TeamContent } from "./TeamContent/TeamContent";
//Styled-Components
import {
  ProfileWrapper,
  ProfileLeft,
  ProfileCenter,
  ProfileRight,
} from "./TeamStyles";

const Team = () => {
  //HARD CODING Framer-motion attributes on each Profile DOM item
  return (
    <div id="team">
      <SectionWrapper inverse={true}>
        <Container>
          <EventHeading
            inverse={"true"}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.3 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            OUR TEAM
          </EventHeading>

          <Ptag>
            At Dynamic, we specialise in corporate services such as golf
            tournaments and team building events through dragon boating, as well
            as providing premium production services for clients. However, we
            are also dedicated to promoting sustainability and reducing our
            carbon footprint.
            <br />
            <br />
            In response to the growing demand for eco-friendly event planning,
            we have established a new division within our company that
            specialises in digitalization. By utilising innovative technologies,
            such as event web apps and digital communication tools, we have been
            able to reduce our paper usage by an average of 80% for each event
            we organise. Our corporate clients have highly appreciated such
            innovation as we jointly move towards sustainability. This not only
            helps to minimise our environmental impact but also streamlines our
            operations, making event planning more efficient and cost-effective.
            <br />
            <br />
            Our commitment to sustainability is not just limited to reducing our
            paper usage, however. We also prioritise the use of eco-friendly
            materials and resources in all aspects of our event planning and
            production. From using biodegradable material for premiums and
            re-usable signages to partnering with local suppliers and
            implementing sustainable transportation options, we strive to create
            events that are both memorable and environmentally responsible.
            <br />
            <br />
            If you are looking for a corporate event planning company that not
            only delivers high-quality services but also prioritises
            sustainability, look no further than Dynamic. We are dedicated to
            creating events that meet your needs while also protecting the
            planet for future generations.
            <br />
            <br />
          </Ptag>

          <ProfileWrapper>
            <ProfileLeft>
              <TeamContent {...natalieLeft} />
            </ProfileLeft>
            <ProfileCenter>
              <TeamContent {...rickCenter} />
            </ProfileCenter>
            <ProfileRight>
              <TeamContent {...listerRight} />
            </ProfileRight>
          </ProfileWrapper>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default Team;
