/////////////////////////////////
//// FRAMER MOTION VARIANTS ////
///////////////////////////////

//BACKGROUND EFFECTS
export const backgroundVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
};

//MODAL EFFECTS
export const modalVariants = {
  initial: {
    opacity: 0,
    // y: 1200,
  },
  animate: {
    opacity: 1,
    // y: 200,
    transition: {
      duration: 0.2,
      // type: "spring",
      // stiffness: 100,
    },
  },
  exit: {
    opacity: 0,
    // y: -200,
  },
};
