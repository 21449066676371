import styled from "styled-components";
import { motion } from "framer-motion";
import { CgCloseO } from "react-icons/cg";

/********************** */
/*  BACKGROUND IMAGE   */
/******************** */
export const Background = styled(motion.div)`
  /* border: green solid 10px; */
  z-index: 9999999999 !important; //Must be above NAV z-index
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  touch-action: manipulation;

  @media screen and (max-width: 420px) {
    background: rgba(0, 0, 0, 1);
  }
`;
/********************** */
/*  MODAL BOX WRAPPER  */
/******************** */

export const ModalWrapper = styled(motion.div)`
  /* border: yellow solid 10px; */
  padding: 0;
  margin: 0;
  max-width: 420px;
  /* max-height: 580px; */
  overflow: scroll;
  /* border-radius: 12px 12px 0px 0px; */
  background: black;
  max-height: 100%;

  @media screen and (max-width: 320px) {
    max-height: 100%;
  }
`;

/********************** */
/*  MODAL IMAGE        */
/******************** */
export const ModalImage = styled(motion.div)`
  /* border: solid red 1px; */

  background-image: url(${(props) => props.backgroundimage});
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  height: 220px;
  max-width: 420px;
  position: relative;
  margin: 0;
  background-color: black;
  /* border: white solid 1px; */
  /* border-radius: 12px 12px 0px 0px; */

  & > img {
    width: 80%;
    height: 80%;
    object-fit: cover;
  }

  @media screen and (max-width: 320px) {
    display: none;
  }
`;

/********************** */
/*  MODAL CONTENT      */
/******************** */
export const ModalContent = styled(motion.div)`
  /* border: blue solid 10px; */
  background: #fbfbfb;
  /* border-radius: 12px; */
`;

export const CloseModalButton = styled(CgCloseO)`
  border-radius: 50%;
  color: #607d8b;
  cursor: pointer;
  position: fixed;
  margin-left: 0.45em;
  margin-top: 1em;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 12;
  //FADE IN VIA CSS
  opacity: 0;
  animation: fadeIn 1.5s ease-in-out forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

//Title Header inside Modal
export const ContentHeading = styled(motion.h2)`
  /* border: solid red 10px; */
  margin: 1rem 0 0.5rem;
  font-size: clamp(1.3rem, 7vw, 3rem);
  line-height: 1em;
  font-weight: 800;
  color: #607d8b;
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 320px) {
    font-size: 2rem;
    padding-top: 20px;
    line-height: 1em;
    font-weight: 800;
  }
`;

//Content Text for Profile FOR TEAM MODAL ONLY!
export const ContentText = styled(motion.p)`
  color: "#6a6a6a";
  max-width: 400px;
  line-height: clamp(1.8rem, 2vw, 2rem);
  margin: 0px 50px 0px 45px;
  font-size: clamp(0.8rem, 1.8vw, 1rem);

  @media screen and (max-width: 500px) {
    margin: 0px 50px 0px 45px;
    font-size: clamp(1rem, 1.8vw, 1.2rem);
  }
`;
