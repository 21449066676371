import styled from "styled-components";
import { motion } from "framer-motion";

//MasonryGrid
export const MasonryGrid = styled.div`
  /* border: solid red 2px; */

  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 300px;
  grid-auto-flow: dense; //Makes images close together
  margin-top: 0.8rem;

  @media screen and (max-width: 420px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > a > img {
      max-height: 300px;
      object-position: center;
    }
  }
`;

//MasonryGrid Event Image Wrapper
export const EventImageWrapper = styled(motion.div)`
  /* border: solid red 2px; */
  display: flex;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  //CLASSES FOR EACH IMAGE either wide, tall or big
  &.wide {
    grid-column: span 2;
  }
  &.tall {
    grid-row: span 2;
  }
  &.big {
    grid-column: span 2;
    grid-row: span 2;
  }

  &:before {
    background: rgb(0, 0, 0, 0.7);
    content: "More Info";
    position: absolute;
    width: 100%;
    z-index: 5;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    transform: translateX(-100%);
    bottom: 0;
    color: white;
  }

  &:hover:before {
    transform: translateX(0);
    transition: transform 0.3s ease;
  }

  &:hover {
    border-radius: 5px;
    > img {
      filter: blur(8px);
    }
  }
  @media screen and (max-width: 420px) {
    //Hide cover with "More Info" on mobile
    &:before {
      display: none;
    }

    &:hover:before {
      display: none;
    }
    &:hover {
      border-radius: 0px;
      > img {
        filter: none;
      }
    }
  }
`;

// Masonary Grid Images
export const Image = styled.img`
  /* border: 10px blue solid; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  object-position: bottom;
  display: inline-block;
  position: relative;
  opacity: 0.7;
`;
