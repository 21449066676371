import React, { useState, useEffect } from "react";
import { CgMenuRound } from "react-icons/cg";
import { CgCloseO } from "react-icons/cg";
import {
  NavbarWrapper,
  NavbarContainer,
  Logo,
  DesktopLinks,
  Language,
  OpenLinksButton,
  MobileLinks,
} from "./NavbarStyles";

import logoNeg from "./NavbarArtwork/logo_neg.svg";
import logo from "./NavbarArtwork/logo.svg";
import TranslationIcon from "./NavbarArtwork/translation_neg.svg";
import TranslationIconNeg from "./NavbarArtwork/translation_neg.svg";
import NavLinks from "./NavLinks";

//REACT SCROLL
import { animateScroll } from "react-scroll";

/* ================================== */
/*  FRAMER - MOTION Variants          */
/* ================================== */
//Framer-Motion for Hamburger inside OepnLinkButton
export const OpenLinkButtonVariants = {
  hidden: {
    opacity: 0,
    y: -40,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring", //Entry Bounce. others include:
      mass: 0.4, //Higher = moves slower, Lower = faster
      damping: 8, //Higher = Less Osolation, Lower = faster
      delay: 0.9,
    },
  },
};
//Framer-Motion for Logo Icon
export const LogoVariants = {
  hidden: {
    opacity: 0,
    x: -40,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring", //Entry Bounce. others include:
      mass: 0.4, //Higher = moves slower, Lower = faster
      damping: 8, //Higher = Less Osolation, Lower = faster
      delay: 0.9,
    },
  },
};
//Framer-Motion for Language Icon
const LanguageVariants = {
  hidden: {
    opacity: 0,
    y: -40,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring", //Entry Bounce. others include:
      mass: 0.4, //Higher = moves slower, Lower = faster
      damping: 8, //Higher = Less Osolation, Lower = faster
      delay: 0.9,
    },
  },
};
/* ================================== */
const NavBar = () => {
  /* ================================== */
  /*  NAVBAR SCROLL  */
  /* ================================== */
  //NAVBAR SCROLL TO SEE -> useSTATE
  const [navbarshow, setNavbarshow] = useState(false); //Don't show when at top of
  //NAVBAR SCROLL TO SEE -> function
  const controlNavbar = () => {
    //distance scrolled down, then appear navbar
    if (window.scrollY <= 200) {
      setNavbarshow(false);
    } else {
      setNavbarshow(true);
    }
  };

  //NAVBAR SCROLL TO SEE -> useEffect
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    //NAVBAR SCROLL TO SEE -> clean up function & remove Event Listener
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  });
  /* ================================== */
  /*  LOGO SCROLL  */
  /* ================================== */
  //LOGO SCROLL TO SEE -> useSTATE
  const [navbarLogo, setNavbarLogo] = useState(logo);
  //LOGO SCROLL TO SEE -> function
  const changeLogo = () => {
    if (window.scrollY >= 200) {
      setNavbarLogo(logoNeg);
    } else {
      setNavbarLogo(logo);
    }
  };

  //LOGO SCROLL TO SEE -> useEffect
  useEffect(() => {
    window.addEventListener("scroll", changeLogo);
    //NAVBAR SCROLL TO SEE -> clean up function & remove Event Listener
    return () => {
      window.removeEventListener("scroll", changeLogo);
    };
  });

  /* ================================== */
  /*  TRANSLATION ICON SCROLL  */
  /* ================================== */
  //TRANSLATION ICON SCROLL TO SEE -> useSTATE
  const [translationIcon, setTranslationIcon] = useState(TranslationIcon);
  //TRANSLATION ICON SCROLL TO SEE -> function
  const changeTranslationIcon = () => {
    if (window.scrollY >= 200) {
      setTranslationIcon(TranslationIconNeg);
    } else {
      setTranslationIcon(TranslationIcon);
    }
  };
  //TRANSLATION ICON SCROLL TO SEE -> useEffect
  useEffect(() => {
    window.addEventListener("scroll", changeTranslationIcon);
    //NAVBAR SCROLL TO SEE -> clean up function & remove Event Listener
    return () => {
      window.removeEventListener("scroll", changeTranslationIcon);
    };
  });

  /* ================================== */
  /*  HAMBURGER ON CLICK  */
  /* ================================== */

  const [open, setOpen] = useState(false); //false=closed, true=opened

  //Hamburger Icon
  const hamburgerIcon = (
    <CgMenuRound size="40px" color="white" onClick={() => setOpen(!open)} />
  );

  //Close Icon
  const closeIcon = (
    <CgCloseO size="40px" color="white" onClick={() => setOpen(!open)} />
  );

  //Prop to close tab in Mobile when link is clicked
  const closeMobileMenu = () => setOpen(false);

  return (
    <>
      <NavbarWrapper color={navbarshow ? "#263238" : "transparent"}>
        <NavbarContainer>
          <DesktopLinks>
            <NavLinks />
          </DesktopLinks>

          <a href="https://dynevents.com/index_c.html">
            <Language
              src={translationIcon}
              variants={LanguageVariants}
              initial="hidden"
              animate="visible"
            />
          </a>
          <Logo
            onClick={animateScroll.scrollToTop}
            src={navbarLogo}
            variants={LogoVariants}
            initial="hidden"
            animate="visible"
          />
          {open && (
            <MobileLinks>
              <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu} />
            </MobileLinks>
          )}

          <OpenLinksButton
            variants={OpenLinkButtonVariants}
            initial="hidden"
            animate="visible"
          >
            {open ? closeIcon : hamburgerIcon}{" "}
          </OpenLinksButton>
        </NavbarContainer>
      </NavbarWrapper>
    </>
  );
};

export default NavBar;
