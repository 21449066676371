import React from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const Emailer = styled.div`
  color: #fcfcfc;
  a {
    text-decoration: none;
    display: inline-block;
  }
`;

const MailTo = ({ mailto, label, children }) => {
  return (
    <Emailer>
      <Link
        to={mailto}
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        {children ? children : <Ptag white="true">{label}</Ptag>}
      </Link>
    </Emailer>
  );
};

export default MailTo;

export const Ptag = styled(motion.div)`
  font-family: "Lora", serif;
  color: #6a6a6a;
  ${(props) =>
    props.white &&
    css`
      color: #fcfcfc;
    `}
  font-size: clamp(1rem, 2vw, 1.4rem);
`;
