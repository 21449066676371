import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";
import GlobalStyle from "./globalStyles";

function App() {
  return (
    <>
      <div>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
