import { useRef } from "react";
import { ButtonTouch } from "../../globalStyles";
import { useButton } from "react-aria";
import { useAnimation } from "framer-motion";

//Component
const ButtonMobile = ({ onClick, children }) => {
  let controls = useAnimation();
  let ref = useRef();
  let { buttonProps } = useButton(
    {
      onPressStart: () => {
        controls.stop();
        controls.set({ background: "#91b5b7" });
      },
      onPressEnd: () => {
        controls.start({
          background: "#657F80",
          transition: { duration: 0.65 },
        });
      },
      onPress: onClick,
    },
    ref
  );
  return (
    <ButtonTouch animate={controls} {...buttonProps}>
      {children}
    </ButtonTouch>
  );
};

export default ButtonMobile;
