import { Container, SectionWrapper, EventHeading } from "../../globalStyles";
import React, { useState } from "react";
//Styled-Componenents
import { ClientUl, ClientLi, ClientDiv } from "./ClientsStyles";

import { ClientsData } from "../../data/ClientsData";

///////////////
//  MODAL   //
/////////////
import Modal from "../Modal/Modal";

//Modal Styled Components:
import {
  Logo, //Logo inside Modal
  // ContentHeading, //Title inside Modal
  Subtitle, //Subtitle inside Modal
} from "../Modal/ModalStyles";
//Modal Component:
import ModalSlideShow from "../Modal/ModalSlideShow";

////////////////////////
// COMPONENT Clients //
//////////////////////
const Clients = ({ i }) => {
  //Framer-Motion PARENT VARIANT
  const list = {
    //initial state
    hidden: {
      opacity: 0,
    },
    //animation
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1, //time to stagger each child elements
      },
    },
  };

  //Framer-Motion Child Variant
  const item = {
    //initial state
    hidden: { opacity: 0, x: -10 },
    //animation
    visible: { opacity: 1, x: 0 },
  };

  //USE STATE = MODAL OPEN & CLOSE
  const [showModal, setShowModal] = useState(false);

  //State for Selected IMAGE property used for Masonry Grid
  //Used to pass in image in MODAL
  const [selectedData, setselectedData] = useState(0);
  //image selected then open same image in index option 1
  const handleClick = (index) => {
    //Option1 single image opens
    setShowModal(true);
    //Option2
    setselectedData(index); //Option2 -> Indicate what project you clicked then sets array index item, then goes to JSON Object and selects the index of item.
  };
  return (
    <>
      <SectionWrapper inverse={"true"}>
        <Container>
          <EventHeading
            inverse={"true"}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 0.3 }}
            variants={{
              visible: { opacity: 1, scale: 1 },
              hidden: { opacity: 0, scale: 0 },
            }}
          >
            OUR CLIENTS
          </EventHeading>

          <ClientUl
            variants={list} //Framer-Motion PARENT Variants
            initial="hidden" // animate="visible" was replaced by whileInViews
            whileInView="visible"
            viewport={{ once: false, amount: 0.1 }} //once: true=one time, once: false=infinite | amount: "some", //"some" or "all" or ""
          >
            {ClientsData.map((element, index) => (
              <ClientLi
                key={element.id}
                variants={item} //Framer-Motion CHILD Variants
                whileHover={{
                  scale: 1.2,
                  transition: { ease: "easeOut", duration: 0.2 },
                }}
              >
                <ClientDiv>
                  <img
                    src={element.photo}
                    alt={element.alt}
                    //DISPLAY MODAL
                    key={index}
                    onClick={() => handleClick(index)}
                  />
                </ClientDiv>
                {/* <p>{element.text}</p> */}
              </ClientLi>
            ))}
          </ClientUl>
        </Container>
      </SectionWrapper>

      {/* **********************
            MODAL COMPONENT 
      ********************** */}

      {/* MODAL COMPONENT */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Logo
          src={ClientsData[selectedData].logo}
          alt={ClientsData[selectedData].alt}
        />

        <ModalSlideShow slides={ClientsData[selectedData]} />

        {/* 
    //FIX:  Received `true` for a non-boolean attribute `inverse` + 'mt'.
    //FIX: replace 'inverse' with 'inverse = { +true} ' 
    //FIX: replace 'mt' with 'mt={+true}w
 */}

        {/* <ContentHeading inverse={+true}>
          {ClientsData[selectedData].modalTitle}
        </ContentHeading> */}

        <Subtitle
          //Framer Motion Fade in
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: ".8", delay: ".55" }}
        >
          {ClientsData[selectedData].modalDescription}
        </Subtitle>
      </Modal>
    </>
  );
};

export default Clients;
