import React from "react";
import { ServicesContent } from "./ServicesContent";

import {
  ServiceOne,
  ServiceTwo,
  ServiceThree,
  ServiceFour,
} from "../../data/ServicesContentData";

export default function Services() {
  return (
    <>
      {/* Use Spread operator to create new array for service section */}
      <ServicesContent {...ServiceOne} />
      <ServicesContent {...ServiceTwo} />
      <ServicesContent {...ServiceThree} />
      <ServicesContent {...ServiceFour} />
    </>
  );
}
