import styled from "styled-components";
import { motion } from "framer-motion";
import { CgCloseO } from "react-icons/cg";
export const Background = styled(motion.div)`
  /* border: green solid 10px; */
  z-index: 9999999999 !important; //Must be above NAV z-index
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);

  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 420px) {
    /* background: rgba(0, 0, 0, 1); */
    background: rgba(0, 0, 0, 1);
  }
`;

export const ModalWrapper = styled(motion.div)`
  /* border: green solid 10px; */

  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  padding: 0.2rem 0rem;
  background: #fff;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 12px;

  @media screen and (max-width: 420px) {
    max-width: 320px;
    padding: 10px 0px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    img {
      max-height: 100%;
    }
  }
  //phone landscape mode
  @media only screen and (max-device-width: 480px) and (orientation: landscape) {
    padding: 0rem;
    background-color: #05121d;
    border: solid #6a6a6a 0.2rem;
  }

  @media screen and (max-width: 320px) {
    max-width: 250px;
    padding: 8px 0px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;

    img {
      max-height: 85%;
    }
  }
`;

export const CloseModalButton = styled(CgCloseO)`
  border-radius: 50%;
  color: #607d8b;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 12;
`;

//LOGO inside MODEL
export const Logo = styled(motion.img)`
  /* border: solid red 10px; */
  max-width: 45%;
  object-fit: cover;
  z-index: 5;
  @media screen and (max-width: 480px) {
    max-width: 60%;
  }
  //phone landscape mode
  @media only screen and (max-device-width: 480px) and (orientation: landscape) {
    display: none;
  }
`;

export const ContentHeading = styled(motion.h2)`
  /* border: solid red 10px; */
  margin: 1rem 0 0.5rem;
  font-size: clamp(1.3rem, 7vw, 3rem);
  line-height: 1em;
  font-weight: 800;
  padding-top: 1em;
  padding-bottom: 1em;
  color: #607d8b;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    margin-bottom: 5px;
    line-height: 16px;
    margin: 10px;
    text-align: center;
  }
  //phone landscape mode
  @media only screen and (max-device-width: 480px) and (orientation: landscape) {
    display: none;
  }
`;

//Subtitle inside Modal
// export const Subtitle = styled(motion.p)`
//   margin-top: 5px;
//   border-left: 10.8px solid #657f80; //Thick border beside words
//   border-right: 10px solid #fff;
//   color: "#6a6a6a";
//   max-width: 400px;
//   /* margin: 1rem; */
//   line-height: 1.5rem;
//   padding-left: 10px;
//   font-size: clamp(0.8rem, 1.8vw, 1rem);
//   text-align: left;

//   @media screen and (max-width: 420px) {
//     margin: 10px;
//   }
// `;
