import styled, { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion"; //For ButtonTouch
import { css } from "styled-components"; //must have for passing color props

export const GlobalStyle = createGlobalStyle`

*{
box-sizing: border-box;
margin: 0px;
padding: 0px;
font-family: 'Heebo', sans-serif;
/* overflow-x:hidden; */
}

body {
font-size: 16px;//needed for REM sizing
overscroll-behavior-y: none;//Prevent overscroll/bounce in Chrome *Does not work for iOS
overscroll-behavior-x: none;//Prevent overscroll/bounce in Chrome *Does not work for iOS
-webkit-overflow-scrolling: touch;//Prevent iOS from Scroll when modal is open
}
//https://dev.to/elijahtrillionz/complete-guide-on-how-to-use-styled-components-in-react-360c
//Scroll bar sample
/* ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  ::-webkit-scrollbar-corner {
    height: 0;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: yellow;
    border-radius: 25px;
  } */


//h1 - same as Heading Styled-Component
h1 {
  color: ${({ inverse }) => (inverse ? "#071c2f" : "#fff")};
  /* <tagName inverse={inverse}>{propname}</tagName> */
  font-size: clamp(3rem, 6vw, 3.5rem);
  line-height: 1em;
  letter-spacing: 0.05ch;
  font-weight: 800;
  padding: 0.5em;
  text-align: center;

  @media screen and (max-width: 420px) {
    font-size: clamp(2.5rem, 4vw, 3rem);
  }
  @media screen and (max-width: 320px) {
    font-size: clamp(2rem, 3vw, 2.5rem);
  }
}

h1 {
  /* border: solid red 10px; */
  margin: 1rem 0 0.5rem;
  font-size: clamp(1.3rem, 7vw, 3rem);
  line-height: 1em;
  font-weight: 800;
  color: "071c2f";
  text-transform: uppercase;
  text-align: center;

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 320px) {
    margin-bottom: 5px;
    line-height: 16px;
    margin: 10px;
  }
}
//Passing Props
//https://styled-components.com/docs/basics#adapting-based-on-props
p {
  font-family: "Lora", serif;
  color: ${(props) => (props.light ? "#FCFCFC" : "#6a6a6a")};
  font-weight: 500;
  line-height: clamp(2rem, 2vw, 3rem);
  font-size: clamp(1rem, 2vw, 1.4rem);
  padding-top: clamp(1rem, 2vw, 2.5rem);
  padding-bottom: clamp(1rem, 2vw, 2.5rem);
  
};


a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    /* padding: 20px 20px 20px 10px; */
  }

`;
/*************************************** */
/*
CLAMP Syntax =
widith between 2.3rem & 4.5rem & equal to 6view 
width but will not go less than 2.3rem.

MIN width 2.3rem 
Preferred 6 view width
MAX width 4.5rem
*/

export const SectionWrapper = styled.div`
  /* border: 10px solid red; */
  padding: 2rem 1rem 2rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ inverse }) => (inverse ? "#fff" : "#05121d")};
  /* <StyledComponentName inverse={inverse}>{propname}</StyledComponentName> */
`;

export const Container = styled.div`
  /* border: solid yellow 2px; */
  //NOTE:!!! Cannot use z-index: 1; or modal will overlap and be buggy
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    /* border: solid green 2px; */
  }
`;

export const EventHeading = styled(motion.div)`
  /* border: solid pink 2px; */
  color: ${({ inverse }) => (inverse ? "#071c2f" : "#fff")};
  /* <StyledComponentName inverse={inverse}>{propname}</StyledComponentName> */
  font-size: clamp(3rem, 6vw, 3.5rem);
  line-height: 1em;
  letter-spacing: 0.05ch;
  font-weight: 800;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: ${({ position }) => (position ? "left" : "center")};
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    font-size: clamp(2.8rem, 4vw, 3.2rem);
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }

  @media screen and (max-width: 500px) {
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  }
  @media screen and (max-width: 320px) {
    padding-bottom: 0.5rem;
  }
`;

/* 
TEAMS EXAMPLE:
          <Ptag white="true">WHITE pTag</Ptag>
          <Ptag>Default color</Ptag>

*/
//CONTENT TEXT
export const Ptag = styled(motion.div)`
  font-family: "Lora", serif;
  /* color: ${(props) => (props.light ? "#FCFCFC" : "#6a6a6a")}; */

  color: #6a6a6a;
  ${(props) =>
    props.white &&
    css`
      color: #fcfcfc;
    `}

  font-weight: 500;
  line-height: clamp(2rem, 2vw, 3rem);
  font-size: clamp(1rem, 2vw, 1.4rem);
  padding-top: clamp(1rem, 2vw, 2.5rem);
  padding-bottom: clamp(1rem, 2vw, 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: left;

  @media screen and (max-width: 900px) {
    line-height: 1.8rem;
    font-size: clamp(1rem, 2vw, 1.3rem);
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    line-height: 2rem;
    font-size: clamp(1.1rem, 2vw, 1.4rem);
    padding: 1em;
  }
  @media screen and (max-width: 500px) {
    line-height: 2.2rem;
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    padding: 0.5em;
  }
  @media screen and (max-width: 320px) {
    line-height: 1.4rem;
    font-size: clamp(1rem, 2vw, 1.2rem);
  }
`;

//BUTTON iOS Touch Style
export const ButtonTouch = styled(motion.button)`
  height: 3rem;
  padding: 16px 32px;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 18px;
  letter-spacing: 1.54px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;

  /* background: none; */
  white-space: nowrap;
  outline: none;
  overflow: hidden;
  position: relative;
  background-color: #657f80;
  border: none; //No border for button
  user-select: none; //Prevent Text Selection
  touch-action: none; //Prevent Scrolling on drag;
  -webkit-tap-highlight-color: "transparent"; //required for iOS touch effect
  //PROPS
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  color: ${({ inverse }) => (inverse ? "#071c2f" : "white")};

  padding: ${({ big }) => (big ? "12px 64px" : "10px 20px")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};

  :focus {
    outline: none; //Required to lose ring on mobile
  }
  @media screen and (max-width: 420px) {
    height: 2.5rem;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 0.7rem;
    line-height: 10px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 4px;
  }
`;

export default GlobalStyle;

//https://medium.com/@personnamedmike/react-styled-components-a-basic-guide-2e03c7bbbfcd

//Overwriting existing styles
// const StyledButton = styled(Button)`
//    && {
//    color: 'red':
//    }
// `;
