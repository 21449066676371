import React from "react";

function WhatsAppIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="30"
      viewBox="0 0 124 30"
      {...props}
    >
      <g>
        <path
          fill="#efefef"
          d="M8,1.5h108c3.3,0,6,2.7,6,6v15c0,3.3-2.7,6-6,6H8c-3.3,0-6-2.7-6-6v-15C2,4.2,4.7,1.5,8,1.5L8,1.5z
		 M8,1.5"
        />
        <path
          fill="#53BA63"
          d="M18.3,8.1c1.8,0,3.6,0.7,4.9,2c1.3,1.3,2.1,3,2.2,4.9
		c0.1,1.8-0.6,3.6-1.8,5c-1.3,1.5-3.1,2.4-5.1,2.4c-0.1,0-0.1,0-0.2,0c-1.1,0-2.1-0.2-3.1-0.7l-3.7,0.8c0,0,0,0,0,0
		c0,0-0.1,0-0.1-0.1l0.6-3.8c-0.5-1-0.8-2.1-0.9-3.2c-0.1-1.9,0.6-3.7,1.9-5.1C14.5,8.9,16.3,8.1,18.3,8.1L18.3,8.1z M18.3,9.3
		c-0.1,0-0.1,0-0.2,0c-3.2,0.1-5.8,2.8-5.7,6c0,1,0.3,2,0.8,2.8l0.1,0.2l-0.5,2.5l2.5-0.6l0.2,0.1c0.8,0.4,1.8,0.7,2.7,0.7
		c0.1,0,0.1,0,0.2,0c3.2-0.1,5.8-2.8,5.7-6C24.1,11.8,21.5,9.3,18.3,9.3L18.3,9.3z M15.8,11.8c0.1,0,0.2,0,0.3,0
		c0.1,0,0.3,0,0.4,0.3c0.1,0.4,0.5,1.2,0.5,1.3c0,0.1,0.1,0.2,0,0.3C17,13.9,17,13.9,16.9,14c-0.1,0.1-0.2,0.2-0.3,0.3
		c-0.1,0.1-0.2,0.2-0.1,0.4c0.1,0.2,0.4,0.8,1,1.3c0.7,0.6,1.2,0.8,1.4,0.9c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2-0.1
		c0.1-0.1,0.4-0.5,0.6-0.7c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,1,0.5,1.2,0.6c0.2,0.1,0.3,0.1,0.3,0.2
		c0,0.1,0,0.4-0.1,0.8c-0.2,0.4-0.9,0.8-1.2,0.8c-0.1,0-0.2,0-0.3,0c-0.3,0-0.8-0.1-1.8-0.5c-1.8-0.8-2.9-2.7-3-2.8
		c-0.1-0.1-0.7-1-0.7-1.9c0-0.9,0.5-1.3,0.7-1.5C15.5,11.8,15.7,11.8,15.8,11.8L15.8,11.8z M15.8,11.8"
        />
        <path
          fill="#53BA63"
          d="M37.2,14.5h1.4c0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.4-0.5-0.6-0.7c-0.2-0.2-0.5-0.3-0.8-0.4
		c-0.3-0.1-0.6-0.2-1-0.2c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.4-1,0.7c-0.3,0.3-0.5,0.7-0.6,1.1c-0.1,0.4-0.2,0.9-0.2,1.3
		c0,0.5,0.1,0.9,0.2,1.3c0.1,0.4,0.4,0.8,0.6,1.1c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.3,1.3,0.3c0.4,0,0.8-0.1,1.1-0.2
		c0.3-0.1,0.6-0.3,0.9-0.5c0.2-0.2,0.4-0.5,0.6-0.8c0.1-0.3,0.2-0.7,0.3-1.1h-1.4c-0.1,0.4-0.2,0.8-0.4,1c-0.2,0.3-0.6,0.4-1,0.4
		c-0.3,0-0.6-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.6,0.1-0.8
		c0.1-0.3,0.2-0.5,0.3-0.7c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.5-0.2,0.8-0.2c0.2,0,0.3,0,0.5,0.1c0.2,0.1,0.3,0.1,0.4,0.2
		c0.1,0.1,0.2,0.2,0.3,0.4C37.2,14.2,37.2,14.3,37.2,14.5L37.2,14.5z M39.5,12.3v6.4h1.3v-2.4c0-0.5,0.1-0.8,0.2-1
		c0.2-0.2,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.6,0.3c0.1,0.2,0.2,0.5,0.2,0.9v2.6h1.3v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.2-0.1-0.4-0.3-0.6
		c-0.1-0.2-0.3-0.3-0.5-0.4C42.8,14,42.5,14,42.1,14c-0.2,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.5,0.3-0.6,0.6h0v-2.4H39.5z M44.8,15.5
		c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.3c0.3-0.1,0.5-0.1,0.8-0.1c0.2,0,0.5,0,0.7,0
		c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.7v2.4c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.1,0.4h-1.3
		c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c-0.2,0.2-0.4,0.4-0.7,0.4c-0.3,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.6-0.1
		c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.3,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4
		c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4-0.1,0.6-0.1c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3-0.1,0.3-0.2
		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.2-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0
		c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.3,0.5H44.8z M47.7,16.5c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.2
		c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.3,0,0.4,0,0.6-0.1
		c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.3V16.5z M51.6,14.1v-1.4h-1.3v1.4h-0.8V15h0.8v2.7
		c0,0.2,0,0.4,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0v-1
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4V15h0.9v-0.9H51.6z M56.8,16.4
		c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1
		c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5
		c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.3-0.2-0.3-0.3c-0.1-0.1-0.1-0.3-0.2-0.5
		C56.8,16.8,56.8,16.6,56.8,16.4L56.8,16.4z M55.5,16.4c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.5,0.4,0.8,0.5
		c0.3,0.1,0.6,0.2,1,0.2c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.3,0.8-0.5c0.2-0.2,0.4-0.5,0.5-0.8c0.1-0.3,0.2-0.6,0.2-1
		c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.2-1-0.2c-0.4,0-0.7,0.1-1,0.2
		c-0.3,0.1-0.5,0.3-0.8,0.5c-0.2,0.2-0.4,0.5-0.5,0.8C55.6,15.7,55.5,16.1,55.5,16.4L55.5,16.4z M61.2,14.1v4.7h1.3v-2.4
		c0-0.5,0.1-0.8,0.2-1c0.2-0.2,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.6,0.3c0.1,0.2,0.2,0.5,0.2,0.9v2.6h1.3v-2.9c0-0.3,0-0.5-0.1-0.8
		c-0.1-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.5-0.4C64.4,14,64.2,14,63.8,14c-0.3,0-0.5,0.1-0.8,0.2c-0.3,0.1-0.5,0.3-0.6,0.6h0
		v-0.6H61.2z M75.2,18.8l1.7-6.4h-1.4l-1.1,4.4h0l-1.1-4.4h-1.3L71,16.7h0l-1-4.4h-1.4l1.7,6.4h1.4l1.1-4.4h0l1.1,4.4H75.2z
		 M77.5,12.3v6.4h1.3v-2.4c0-0.5,0.1-0.8,0.2-1c0.2-0.2,0.4-0.3,0.8-0.3c0.3,0,0.5,0.1,0.6,0.3c0.1,0.2,0.2,0.5,0.2,0.9v2.6h1.3
		v-2.9c0-0.3,0-0.5-0.1-0.8c0-0.2-0.1-0.4-0.3-0.6c-0.1-0.2-0.3-0.3-0.5-0.4C80.8,14,80.5,14,80.2,14c-0.2,0-0.5,0.1-0.7,0.2
		c-0.3,0.1-0.5,0.3-0.6,0.6h0v-2.4H77.5z M82.8,15.5c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.3
		C84.4,14,84.7,14,85,14c0.2,0,0.5,0,0.7,0c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.2,0.7v2.4
		c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.3,0.1,0.4h-1.3c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c-0.2,0.2-0.4,0.4-0.7,0.4
		c-0.3,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.3,0-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4-0.1,0.6-0.1
		c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.2-0.2
		c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.1-0.2,0.3-0.2,0.5H82.8z M85.7,16.5
		c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.1
		c-0.1,0-0.1,0.1-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0.1,0.2,0.1
		c0.1,0,0.2,0,0.3,0c0.3,0,0.4,0,0.6-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.2,0-0.3V16.5z M89.6,14.1v-1.4
		h-1.3v1.4h-0.8V15h0.8v2.7c0,0.2,0,0.4,0.1,0.6c0.1,0.1,0.2,0.3,0.3,0.3c0.1,0.1,0.3,0.1,0.5,0.2c0.2,0,0.4,0,0.6,0
		c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0v-1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4V15
		h0.9v-0.9H89.6z M92.2,17.2h-1.2c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.7,0.3c0.3,0.1,0.5,0.1,0.8,0.1
		c0.3,0,0.5,0,0.8-0.1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.5,0.2-0.8c0-0.2,0-0.4-0.1-0.5
		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.3-0.2-0.5-0.2C94.1,16,94,16,93.8,15.9c-0.2,0-0.4-0.1-0.5-0.1c-0.2,0-0.3-0.1-0.5-0.1
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1
		c0.1,0,0.2,0,0.2,0c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.3,0.5h1.2c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.5
		c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5-0.1-0.7-0.1c-0.3,0-0.5,0-0.7,0.1c-0.2,0-0.5,0.1-0.6,0.2c-0.2,0.1-0.3,0.3-0.5,0.4
		c-0.1,0.2-0.2,0.4-0.2,0.7c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.1,0.5,0.1
		c0.5,0.1,0.8,0.2,1.1,0.3c0.3,0.1,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1
		c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.2C92.2,17.5,92.2,17.4,92.2,17.2L92.2,17.2
		z M97.7,16.3l0.8-2.4h0l0.8,2.4H97.7z M97.9,12.3l-2.4,6.4h1.4l0.5-1.4h2.4l0.5,1.4h1.5l-2.4-6.4H97.9z M104.5,17.9
		c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.4,0.1-0.6
		c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1c0.1,0.1,0.3,0.2,0.3,0.3
		c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.2-0.3,0.3
		C104.9,17.9,104.7,17.9,104.5,17.9L104.5,17.9z M102.2,14.1v6.3h1.3v-2.2h0c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.8,0.2
		c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.6-0.5c0.2-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6-0.1-0.9
		c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.6h0
		v-0.6H102.2z M110,17.9c-0.2,0-0.4,0-0.5-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.2-0.1-0.4-0.1-0.5
		c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.1,0.5-0.1c0.2,0,0.4,0,0.5,0.1
		c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.5c0,0.2-0.1,0.3-0.2,0.5
		c-0.1,0.1-0.2,0.2-0.3,0.3C110.4,17.9,110.2,17.9,110,17.9L110,17.9z M107.7,14.1v6.3h1.3v-2.2h0c0.2,0.2,0.4,0.4,0.6,0.5
		c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.6-0.5c0.2-0.2,0.3-0.5,0.4-0.8c0.1-0.3,0.1-0.6,0.1-0.9
		c0-0.3,0-0.6-0.1-0.9c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.5c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.8,0.2
		c-0.2,0.1-0.4,0.3-0.6,0.6h0v-0.6H107.7z M107.7,14.1"
        />
      </g>
    </svg>
  );
}

export default WhatsAppIcon;
