import React from "react";

import { Container, SectionWrapper } from "../../globalStyles";
import { FooterCopyright } from "./FooterStyles";
import FooterCoMap from "./FooterCoMap";

const Footerapp = () => {
  return (
    <>
      <div id="footer" />
      <SectionWrapper inverse={false}>
        <Container>
          <FooterCoMap />
          <FooterCopyright>
            © 2002 - {new Date().getUTCFullYear()} DYNAMIC MANAGEMENT GROUP
            LIMITED. ALL RIGHTS RESERVED.
          </FooterCopyright>
        </Container>
      </SectionWrapper>
    </>
  );
};

export default Footerapp;
