import styled from "styled-components";
import { motion } from "framer-motion";

export const Menu = styled(motion.ul)`
  /* border: blue solid 3px; */
  display: flex;
  list-style: none;
  list-style-type: none;

  @media only screen and (max-width: 960px) {
    /* border: 3px solid black; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
export const MenuItem = styled(motion.li)`
  /* border: solid red 1px; */
  z-index: 9 !important;
  align-items: center;
  text-align: center;
  padding: 20px 20px 0px 20px;
  user-select: none; //Prevent Text Selection
  touch-action: manipulation; // prevent delay of box
  outline: none;
  border: none;
  -webkit-tap-highlight-color: "transparent";
  @media only screen and (max-height: 600px) {
    /* border: 3px solid black; */
    padding: 0px 0px 0px 0px;
  }

  a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    position: relative;
    padding: 10px 10px 20px 10px; //globalStyles is different!!!
  }
  a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: #fff;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  a:hover:after {
    width: 100%;
    left: 0;
  }

  @media only screen and (max-width: 960px) {
    font-size: 36px;
    line-height: 2.8rem;
  }
  @media only screen and (max-height: 600px) {
    font-size: 16px;
    line-height: 1rem;
  }
`;
