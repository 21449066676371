export const natalieLeft = {
  id: 1,
  name: "NATALIE FUNG",
  title: "DIRECTOR",
  img: "./assets/images/Team/nataliefrontend.jpg",
  alt: "NATALIE FUNG",

  // *********************//
  //      MODAL PROPS     //
  // *********************//
  linkImage: "./assets/images/Team/nataliefung.jpg",
  modalName: "DIRECTOR",

  modalp1:
    "Natalie Fung is a highly accomplished director in the sports event management industry, with over 9 years of experience working for Dynamic Management Group Ltd. During her time with the company, Natalie has established herself as a skilled professional with a proven track record of delivering successful events for high-end corporate clients.",
  modalp2:
    "With the onset of the COVID-19 pandemic, Natalie recognized the need to pivot and adapt to the changing landscape of the industry. Taking on a new challenge, she began exploring the world of digital business and programming.",
  modalp3:
    "Over the past two years, Natalie has dedicated herself to developing her skills in digital business and programming. She has been instrumental in developing the RSVP and registration system, as well as creating engaging photo arrangements for events. With her innovative approach, Natalie has been able to streamline event planning and create a more seamless experience for attendees.",
  modalp4:
    "In addition, Natalie has also adopted technology in her premiums production business, implementing an online ordering and tracking system. This has allowed her to improve efficiency and provide a better customer experience for her clients.",
  modalp5:
    "With her extensive experience in sports event management and her growing proficiency in digital business and programming, Natalie is well-positioned to continue driving innovation in the industry and help shape the future of sports events. Her dedication to adopting new technologies and finding creative solutions has made her a respected leader in the field",
};

export const rickCenter = {
  id: 2,
  name: "RICK CHU",
  title: "FOUNDER",
  img: "./assets/images/Team/rickfrontend.jpg",
  alt: "RICK CHU",

  // *********************//
  //      MODAL PROPS     //
  // *********************//
  linkImage: "./assets/images/Team/rickchu.jpg",
  modalName: "FOUNDER",

  modalp1:
    "Rick established Dynamic Management Group Limited (formerly JJN EVENT MANAGEMENT) in May 2002, capitalizing on the surging demand for top-notch sports and corporate hospitality events.  Since 2008 onwards, Rick has successfully hosted the annual finale of the renowned MercedesTrophy Hong Kong golf tournament, earning a reputation for its impeccable organization, breathtaking venues and unforgettable experiences for competitors and guests alike.",

  modalp2:
    "Rick entered the Asian sports industry in 1995, starting as an account executive at Octagon (formally known as API-Prism), where he worked on media and promotions for the Hong Kong Rugby Sevens and Johnnie Walker International Golf Programme.  Rick’s key experience has come from acting as a consultant for Cathay Pacific’s Golfing Breaks in 1996, organizing golf travel packages and tailoring unique corporate golf events for a wide range of companies.",

  modalp3:
    "From 1998 to 2001, Rick managed the local version of the World Corporate Golf Challenge, an inter-company golf event where winners from over 27 countries came together to participate in.  Relying on his vast experience within the golf industry, Rick had also developed the 2001-2002 American Express Centurion and Platinum credit card golf program, TaylorMade and Acanta Cigars golf campaign.",

  modalp4:
    "Rick was also the project manager for the HSBC Hong Kong International Basketball Challenge, which involved NBA Stars Yao Ming and Mengke Bateer, playing on the China National Team against the Melbourne Tigers of Australia.",

  modalp5:
    "As the founder of Dynamic Management Group Limited, Rick continues to provide expert event management services to clients in Hong Kong and has aspirations to expand the company’s reach in the Asia region.  With over two decades’ worth of experience in the sports and event industry, Rick brings a wealth of knowledge and creativity to each project, ensuring that clients receive unique and memorable experiences.",
};

export const listerRight = {
  id: 3,
  name: "LISTER WOO",
  title: "ADVISOR",
  img: "./assets/images/Team/listerfrontend.jpg",
  alt: "LISTER WOO",

  // *********************//
  //      MODAL PROPS     //
  // *********************//
  linkImage: "./assets/images/Team/listerwoo.jpg",
  modalName: "ADVISOR",

  modalp1:
    "Lister Woo is a seasoned paddling specialist and the founder of Typhoon8 Limited.  With a passion for promoting dragon boat events, he has established a reputable partnership with Dynamic Management Group, hosting all Dragon Boat team building events across Asia.",

  modalp2:
    "Lister has a wealth of experience in both coaching and training dragon boat team building events, as well as in the manufacturing of elite paddling equipment.  He is widely recognised as the first International Dragon Boat Federation certified dragon boat paddle manufacturer in Asia, reflecting his expertise in the industry.",

  modalp3:
    "Lister’s achievements extend beyond the manufacturing arena, as he has also enjoyed great success in international and regional paddling competitions.",

  modalp4:
    "As a paddling specialist and experienced coach, Lister brings hands-on training and a wealth of knowledge to each team building event.",

  modalp5:
    "His dedication to the sport and passion for creating unique and memorable experiences have earned him a reputation as one of the leading experts in the industry.",
};
