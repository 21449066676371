import React from "react";

const WhatsAppLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    style={{ enableBackground: "new 0 0 32 32" }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}`}
    </style>
    <path
      className="st0"
      d="M27,5C24.1,2,20.2,0.4,16.1,0.4C7.6,0.4,0.6,7.3,0.6,15.9c0,2.7,0.7,5.4,2.1,7.7l-2.2,8l8.2-2.1
        c2.3,1.2,4.8,1.9,7.4,1.9h0c8.5,0,15.4-6.9,15.4-15.4C31.5,11.7,29.9,7.9,27,5L27,5z M16.1,28.7L16.1,28.7c-2.3,0-4.6-0.6-6.5-1.8
        l-0.5-0.3l-4.9,1.3l1.3-4.7l-0.3-0.5c-1.3-2-2-4.4-2-6.8C3.2,8.8,9,3,16.1,3c3.4,0,6.6,1.3,9.1,3.8c2.4,2.4,3.8,5.6,3.8,9.1
        C28.9,22.9,23.1,28.7,16.1,28.7L16.1,28.7z M23.1,19.1c-0.4-0.2-2.3-1.1-2.6-1.3c-0.4-0.1-0.6-0.2-0.9,0.2c-0.3,0.4-1,1.3-1.2,1.5
        c-0.2,0.3-0.5,0.3-0.8,0.1c-0.4-0.2-1.6-0.6-3.1-1.9c-1.1-1-1.9-2.3-2.1-2.7c-0.2-0.4,0-0.6,0.2-0.8c0.2-0.2,0.4-0.5,0.6-0.7
        c0.2-0.2,0.3-0.4,0.4-0.6c0.1-0.3,0.1-0.5,0-0.7c-0.1-0.2-0.9-2.1-1.2-2.9c-0.3-0.8-0.6-0.6-0.9-0.7c-0.2,0-0.5,0-0.7,0
        c-0.3,0-0.7,0.1-1,0.5c-0.4,0.4-1.3,1.3-1.3,3.2s1.4,3.7,1.6,4c0.2,0.3,2.7,4.2,6.6,5.8c0.9,0.4,1.6,0.6,2.2,0.8
        c0.9,0.3,1.8,0.3,2.4,0.2c0.7-0.1,2.3-0.9,2.6-1.8c0.3-0.9,0.3-1.7,0.2-1.8C23.7,19.4,23.5,19.3,23.1,19.1L23.1,19.1z"
    />
  </svg>
);

export default WhatsAppLogo;
