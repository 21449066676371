import React, { useRef, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import {
  Background,
  ModalContent,
  CloseModalButton,
  ModalWrapper,
} from "./TeamModalStyles";

import { backgroundVariants, modalVariants } from "./TeamVariants";

//Passing properties
const TeamModal = ({ show, onHide, children }) => {
  const modalRef = useRef();

  //Function to CLOSE modal
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onHide();
    }
  };

  /////////////////////////////////
  /// useEffect Hook for MODAL ///
  ///////////////////////////////
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [show]);

  ////////////////////////
  /// MODAL COMPONENT ///
  //////////////////////
  return (
    <AnimatePresence>
      {show && (
        <Background
          variants={backgroundVariants}
          animate="animate"
          initial="initial"
          //click Event
          onClick={closeModal}
          //Check if you click on background to HIDE modal
          ref={modalRef}
          exit={{
            opacity: 0,
          }}
        >
          <ModalWrapper
            variants={modalVariants}
            animate="animate"
            initial="initial"
            exit={{
              opacity: 0,
              y: "-100vh",
            }}
          >
            <ModalContent>
              <CloseModalButton aria-label="Close modal" onClick={onHide} />
              {children}
            </ModalContent>
          </ModalWrapper>
        </Background>
      )}
    </AnimatePresence>
  );
};

export default TeamModal;
