import styled from "styled-components";
import { motion } from "framer-motion";

export const ContentRow = styled.div`
  /* border: solid 1px green; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const ContentColumn = styled(motion.div)`
  /* border: solid 1px gray; */

  margin-right: 2rem;
  margin-left: 2rem;
  flex: 1;
  z-index: 3;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    max-width: 100% !important;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const TextWrapper = styled.div`
  padding-bottom: 2rem;
  @media screen and (max-width: 768px) {
    > h1,
    p {
      text-align: left;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > img {
    width: 300px;
    margin-left: -3px; //?????????
  }
`;

export const ImgWrapper = styled(motion.div)`
  border: solid #a4a3a3 12px;
  border-radius: 5px;
  display: flex;
  justify-content: "flex-end";
  max-height: 700px;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
  }

  @media screen and (max-width: 420px) {
    border: solid #a4a3a3 8px;
  }
`;

export const Img = styled(motion.img)`
  box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.8);
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  object-fit: cover;
  max-height: 700px;
  z-index: 1;
  cursor: pointer;

  @media screen and (max-width: 420px) {
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2),
      0 2px 15px 0 rgba(0, 0, 0, 0.8);
  }
`;

////////////////////////////////////////
//CHANGED to Subtitle to SubtitleText //
// as it clashes with Modal           //
////////////////////////////////////////
export const SubtitleText = styled(motion.div)`
  font-family: "Lora", serif;
  color: ${({ inverse }) => (inverse ? "#6a6a6a" : "#FCFCFC")};
  font-weight: 500;
  line-height: clamp(2rem, 2vw, 3rem);
  font-size: clamp(1rem, 1.5vw, 2.5rem);
  /* padding-top: clamp(1rem, 2vw, 2.5rem); */
  padding-bottom: clamp(1rem, 2vw, 2.5rem);
`;

/**************************** 
FRAMER MOTION CODE:
*****************************/

export const zoomVariants = {
  WhileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 0.3,
      ease: "easeInOut",
    },
  },
  //TEXT
  Viewport: {
    once: false, //true=once, false=infinite
    amount: "some", //"some" or "all" or ""
  },
};

export const slideVariants = {
  WhileInView: {
    y: [-100, 0],
    x: [10, 0],
    scale: [1.0, 1.3, 1.0],
    opacity: [0, 1],
    transition: {
      duration: 0.4,
      ease: "easeInOut",
    },
  },
  //Image
  Viewport: {
    once: true, //true=once, false=infinite
    amount: "1", //% of viewport to trigger animation. 1=100%
  },
};
