import styled from "styled-components";
import { motion } from "framer-motion";

export const SlideShowWrapper = styled.div`
  /* border: solid red 3px; */
  width: 420px;
  height: 280px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 420px) {
    max-width: 320px;
    height: 220px;
  }
  @media screen and (max-width: 320px) {
    max-width: 250px;
    height: 180px;
  }
`;

export const SlideShowContainer = styled.div`
  /* border: 5px green solid; */
  background-color: #05121d;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SlideShowImage = styled(motion.img)`
  /* border: 5px black solid; */
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
`;

export const SlideNext = styled(motion.div)`
  top: calc(50% - 20px);
  position: absolute;
  background: #fff;
  color: #000000;
  border: solid 0.8px #b0bec5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 25px;
  z-index: 2;
  right: 20px;
  :focus {
    outline: none; //Required to lose ring on mobile
  }

  @media screen and (max-width: 420px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
  @media screen and (max-width: 320px) {
    display: none;
  }
`;

//LEFT SIDE
export const SlidePrev = styled(motion.div)`
  top: calc(50% - 20px);
  position: absolute;
  background: #fff;
  color: #000000;
  border: solid 0.8px #b0bec5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-size: 25px;
  z-index: 2;
  left: 20px;
  :focus {
    outline: none; //Required to lose ring on mobile
  }

  @media screen and (max-width: 420px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }

  @media screen and (max-width: 320px) {
    display: none;
  }
`;
