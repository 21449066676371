//https://reactsvgicons.com/
import React from "react";

function IconEmailBox(props) {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height="2rem"
        width="2rem"
        {...props}
      >
        <path d="M5 3a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2H5m1.4 3.5h11.2c.77 0 1.4.62 1.4 1.4v8.2a1.4 1.4 0 01-1.4 1.4H6.4c-.77 0-1.4-.63-1.4-1.4V7.9c0-.78.62-1.4 1.4-1.4M6 8v2l6 4 6-4V8l-6 4-6-4z" />
      </svg>
    </>
  );
}

export default IconEmailBox;
