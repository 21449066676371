import styled from "styled-components";
import { motion } from "framer-motion";

export const ProfileBackgroundImage = styled.div`
  /* border: 10px solid blue; */
  /* z-index: 1; */
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  /* border: 10px solid green; */
  margin-top: auto; //Keeps div at bottom in flexbox
  user-select: none; //Prevents text copy
  line-height: 0.5rem;
`;

export const TitleBox = styled.div`
  /* border: 10px solid red; */
  width: 100%;
`;
// *************************************
// Transparent Text Cut Out Background
// ************************************
export const TitleText = styled(motion.div)`
  padding-top: 3%;
  padding-right: 3%;
  padding-left: 3%;
  padding-bottom: 3%;
  overflow: hidden;
  text-transform: uppercase;
  color: #080404;
  background-color: rgba(255, 255, 255, 0.95); //WHITE
  /* that css is the main think (mix-blend-mode: lighten;)*/
  mix-blend-mode: lighten;
`;

export const NameFont = styled.div`
  font-size: clamp(2.5rem, 6vw, 3rem);
  line-height: 1em;
  letter-spacing: 0.05ch;
  font-weight: 800;
  text-align: left;

  @media screen and (max-width: 420px) {
    font-size: clamp(2.5rem, 4vw, 3rem);
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    font-size: clamp(2rem, 3vw, 2.5rem);
    text-align: center;
  }
`;
export const TitleFont = styled.div`
  font-size: clamp(1.2rem, 3vw, 2rem);
  line-height: 1em;
  letter-spacing: 0.05ch;
  font-weight: 800;
  text-align: left;

  @media screen and (max-width: 420px) {
    font-size: clamp(1.2rem, 3vw, 1.8rem);
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    font-size: clamp(1rem, 2vw, 1.5rem);
    text-align: center;
  }
  @media screen and (max-width: 280px) {
    display: none;
  }
`;
