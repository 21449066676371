import { useState, useEffect, useRef } from "react";
import { images } from "../../data/HeroData";
import TitleSvg from "./TitleSvg/TitleSvg";

import styled from "styled-components";

const HeroWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #000;
`;

const HeroImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;

  opacity: ${(props) => (props.isActive ? 1 : 0)};
  transition: opacity 3.8s ease-in-out, transform 2.5s ease-in-out;
  transform: ${(props) => (props.isActive ? "scale(1.02)" : "")};
`;

const Hero = () => {
  const [index, setIndex] = useState(0);
  const touchStartX = useRef(null);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleTouchStart = (event) => {
    touchStartX.current = event.changedTouches[0].screenX;
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].screenX;
    const threshold = 100;
    if (touchEndX < touchStartX.current - threshold) {
      setIndex((index) => (index + 1) % images.length);
    }
  };

  return (
    <HeroWrapper
      id="hero"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <HeroImageWrapper>
        {images.map((image, i) => (
          <HeroImage
            src={image.src}
            alt={image.alt}
            key={i}
            isActive={i === index}
            e
          />
        ))}
      </HeroImageWrapper>
      <TitleSvg />
    </HeroWrapper>
  );
};

export default Hero;
