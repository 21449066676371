import styled from "styled-components";
import { motion } from "framer-motion";

export const ClientUl = styled(motion.ul)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
export const ClientLi = styled(motion.li)`
  list-style: none;
  display: flex;
  margin: 2.2rem;
`;
export const ClientDiv = styled(motion.div)`
  height: 60px;
  width: 130px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  //Aligns Div items in center
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 500px) {
    height: 80px;
    width: 100px;
  }
  @media screen and (max-width: 400px) {
    height: 70px;
    width: 70px;
  }
  @media screen and (max-width: 350px) {
    height: 40px;
    width: 0px;
  }

  img {
    width: 160px;
    position: relative;
    object-fit: cover;
    user-select: none; //Prevent Text Selection
    touch-action: none; //Prevent Scrolling on drag;
    -webkit-tap-highlight-color: "transparent"; //required for iOS touch effect
    //PROPS

    filter: grayscale(100%);
    @media screen and (max-width: 960px) {
      width: 140px;
    }
    @media screen and (max-width: 501px) {
      width: 120px;
    }
    @media screen and (max-width: 400px) {
      width: 80px;
    }
    @media screen and (max-width: 350px) {
      width: 65px;
    }
  }
`;
